import React, { useEffect, useState } from 'react';
import './article.css';
import ColorButton from '../../components/ColorButton';
import ActionButton from '../../components/ActionButton';
import { Row, Col, Container } from 'react-bootstrap';

import evTeal from '../../assets/exploded-teal.png';
import evBlue from '../../assets/exploded-blue.png';
import evBlack from '../../assets/exploded-black.png';
import evPink from '../../assets/exploded-pink.png';
import HeaderImage from '../../assets/hydration-boosts-nutrition/header.jpeg';
import FooterImage from '../../assets/exercise-article/footer.jpg';

import r1 from '../../assets/hydration-boosts-nutrition/image1.jpg';
import r2 from '../../assets/hydration-boosts-nutrition/image2.jpeg';
import r3 from '../../assets/hydration-boosts-nutrition/image3.jpeg';
import r4 from '../../assets/hydration-boosts-nutrition/image4.jpeg';
import r5 from '../../assets/hydration-boosts-nutrition/image5.jpeg';
import r6 from '../../assets/hydration-boosts-nutrition/image6.jpeg';

import ExternalHeader from '../../components/ExternalHeader';
import SocialMediaIcons from '../../components/SocialMediaIcons';
import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer'

export const explodedView = {
  teal: evTeal,
  blue: evBlue,
  black: evBlack,
  pink: evPink,
};
const bottleColors = {
  teal: 'teal',
  blue: 'blue',
  black: 'black',
  pink: 'pink',
};

const HydrationBoostsNutritionArticle = (props) => {
  const [sbottle, setSBottle] = useState(bottleColors.black);
  const [buttonColor, setButtonColor] = useState('bg-gray-600');

  const learnMoreLink = props.learnMoreLink
    ? props.learnMoreLink
    : 'https://www.torontotechgear.com/?source=hydration-boost-nutrition-article';
  console.log(learnMoreLink);
  const sectionOneParagraphs = [
    {
      title: `Beyond Thirst Quenching`,
      description: [
        `Most individuals recognize the importance of hydration for basic physiological functions such as temperature regulation and waste elimination. However, hydration's role in nutrient absorption is less commonly understood. `,
        `The digestive system employs a variety of enzymes and processes to extract nutrients from food, and water is essential at multiple stages of this complex biochemical pathway.`,
      ],
      image: r1,
    },
    {
      title: `Digestion: A Biochemical Cascade`,
      description: [
        `Digestion commences in the oral cavity, where enzymes like amylase initiate the breakdown of complex carbohydrates. Proteins begin to denature and unravel within the acidic environment of the stomach, further exposing them to enzymatic degradation.`,
        `Nonetheless, the small intestine serves as the primary site for nutrient absorption.`,
      ],
      image: r2,
    },
    {
      title: `The Small Intestine: The Hub of Nutrient Absorption`,
      description: [
        `Contrary to what its name may imply, the small intestine plays a significant role in digestion and nutrient absorption. Upon arrival in the small intestine, the mixture of partially digested food and gastric secretions—referred to as chyme—becomes subject to further enzymatic breakdown (1).`,
      ],
      image: r3,
    },
    {
      title: `Water as a Solvent in Nutrient Absorption`,
      description: [
        `Water is often described as the "universal solvent" due to its ability to dissolve a multitude of substances. Within the small intestine, water facilitates the solubilization of various nutrients, converting them into forms that can be readily absorbed across the intestinal lining (2).`,
      ],
      image: r4,
    },
    {
      title: `Impact of Hydration on Nutrient Transport`,
      description: [
        `In a well-hydrated state, adequate water is available in the gastrointestinal tract to support enzymatic activities and nutrient solubilization. Furthermore, water serves as the medium for the transport of these nutrients through the intestinal lining and into the bloodstream (3).`,
      ],
      image: r5,
    },
    {
      title: `The Consequences of Dehydration`,
      description: [
        `Insufficient hydration can compromise the digestive system at multiple levels. Reduced enzymatic activity and lower intestinal permeability are likely consequences, which result in inefficient nutrient absorption (4).`,
      ],
      image: r6,
    },
  ];

  const imageStyle = {
    aspectRatio: '3/2',
    objectFit: 'cover',
    display: 'flex',
    width: '100%',
  };
  return (
    <div className="article-container">
      <Helmet>
        <title>
          Hydration for Optimal Nutrient Absorption - Toronto Tech Gear
        </title>
        <meta
          name="description"
          content="Proper hydration is not merely a matter of quenching thirst; it is a cornerstone for the optimal absorption of nutrients within the body. Adequate water levels in the digestive system support enzymatic activities and facilitate the breakdown and transport of nutrients across the intestinal lining into the bloodstream. Therefore, maintaining appropriate hydration levels is critical for optimal health."
        />
      </Helmet>
      <ExternalHeader
        nextArticleLink="/exercise-mental-health-article"
        nextArticleTitle="Why Drinking Water is Important"
        ttglogo={true}
        productLink={learnMoreLink}
      />
      <div className="body-padding">
        <div id="title">
          <h1 className="mb-4 mt-8">
            The Science of Hydration: Why Water Needs to Be Part of Your Diet 
          </h1>
          <p>
            September 10<sup>th</sup>, 2023
          </p>
        </div>
        <Container fluid>
          <Row>
            <Col className="mt-4" xl={8} md={12} sm={12}>
              <img style={imageStyle} alt="header" src={HeaderImage} />
            </Col>
            <Col className="mt-4">
              <p style={{ display: 'flex' }}>
                Proper hydration is not merely a matter of quenching thirst; it
                is a cornerstone for the optimal absorption of nutrients within
                the body.
                <br />
                <br />
                Adequate water levels in the digestive system support enzymatic
                activities and facilitate the breakdown and transport of
                nutrients across the intestinal lining into the bloodstream.
                Therefore, maintaining appropriate hydration levels is critical
                for optimal health.
              </p>
            </Col>
          </Row>
        </Container>
        {sectionOneParagraphs.map((r, index) => (
          <div className="mb-8">
            <h2 className="xs:pt-0 sm:pt-12">{r.title}</h2>
            <Container>
              <Row>
                <Col xl={8} md={12} sm={12}>
                  <img
                    className="mt-2"
                    style={{...imageStyle,aspectRatio:r.title==='Beyond Thirst Quenching'?'1/1':'3/2'}}
                    alt={index.toString() + '-b'}
                    src={r.image}
                  />
                </Col>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '1rem',
                    }}
                  >
                    {r.description.map((item) => (
                      <p>{item}</p>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ))}
        <div className="pb-0"></div>
        <div>
        <h2>Conclusion</h2>
          <p>
            Water is a critical but often overlooked component of optimal
            nutrient absorption. Through its role as a solvent and transport
            medium, it ensures effective enzymatic action and nutrient passage
            across the intestinal lining. <strong>Maintaining adequate hydration is,
            therefore, not merely a matter of avoiding dehydration but also of
            optimizing nutritional health.</strong>
          </p>
        </div>
        <img style={imageStyle} alt="header" src={FooterImage} />
        <h1 className="mt-8">We Are Here to Help</h1>
        <p>
          Toronto Tech is on a mission to improve people’s wellbeing through
          nutrition, hydration, and exercise. That is why we have created the
          perfect gear to help you stay hydrated and active wherever you go.
        </p>
        <div className="mt-8 items-center flex-col justify-center gap-10 md:mt-4 md:flex">
          {/* GRAPHIC */}
          <div className="flex items-center justify-center gap-4 ">
            <ColorButton
              onClick={() => {
                setButtonColor('bg-blueBottle');
                setSBottle(bottleColors.blue);
              }}
              color="bg-blueBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-gray-600');
                setSBottle(bottleColors.black);
              }}
              color="bg-blackBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-tealDark');
                setSBottle(bottleColors.teal);
              }}
              color="bg-tealBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-pinkDark');
                setSBottle(bottleColors.pink);
              }}
              color="bg-pinkBottle"
            />
          </div>
          <img
            style={{ objectFit: 'cover', maxWidth: '100%', maxHeight: '80%' }}
            alt="benefits-page-graphic"
            src={explodedView[sbottle]}
            className="mb-2 md:mb-0"
          />
          {/* DESCRIPTION */}
          <div
            style={{ textDecoration: 'none' }}
            className="w-full  items-center justify-center flex md:mt-0 xs:mt-4"
          >
            <ActionButton
              color={buttonColor}
              href={learnMoreLink}
              target="_blank"
            >
              Learn More
            </ActionButton>
          </div>
        </div>
        <div className="mt-10 mb-10">
          <h1>Follow us</h1>
          <SocialMediaIcons />
        </div>

        <h2>Sources:</h2>
        <p>
          (1) Gropper, S. S., Smith, J. L., & Carr, T. P. (2017). Advanced
          Nutrition and Human Metabolism. Cengage Learning.
          <p className="source">
            https://www.cengage.com/c/advanced-nutrition-and-human-metabolism-7e-gropper/9781305627857/
          </p>
        </p>

        <p>
          (2) Whitney, E. N., & Rolfes, S. R. (2019). Understanding Nutrition.
          Cengage Learning.
          <p className="source">
            https://www.cengage.com/c/understanding-nutrition-15e-whitney/9780357452491/
          </p>
        </p>

        <p>
          (3) Popkin, B. M., D'Anci, K. E., & Rosenberg, I. H. (2010). Water,
          Hydration, and Health. Nutrition Reviews, 68(8), 439–458.
          <p className="source">
            https://doi.org/10.1111/j.1753-4887.2010.00304.x
          </p>
        </p>

        <p>
          (4) Institute of Medicine (US) Committee on Water and Electrolytes.
          (2005). Dietary Reference Intakes for Water, Potassium, Sodium,
          Chloride, and Sulfate. National Academies Press.
          <p className="source">
            https://www.ncbi.nlm.nih.gov/books/NBK208880/
          </p>
        </p>

        <p>
          (5) Jequier, E., & Constant, F. (2010). Water as an essential
          nutrient: The physiological basis of hydration. European Journal of
          Clinical Nutrition, 64(2), 115–123.
          <p className="source">https://doi.org/10.1038/ejcn.2009.111</p>
        </p>

        <Footer/>
      </div>
    </div>
  );
};

export default HydrationBoostsNutritionArticle;
