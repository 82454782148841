import React, { useState } from 'react'
import './article.css'
import ColorButton from '../../components/ColorButton'
import ActionButton from '../../components/ActionButton'
import { Row, Col, Container } from 'react-bootstrap'
import Footer from '../../components/Footer'

import evTeal from '../../assets/exploded-teal.png'
import evBlue from '../../assets/exploded-blue.png'
import evBlack from '../../assets/exploded-black.png'
import evPink from '../../assets/exploded-pink.png'

import ExternalHeader from '../../components/ExternalHeader'
import SocialMediaIcons from '../../components/SocialMediaIcons'
import { Helmet } from 'react-helmet-async'

const HeaderImage = 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218839/toronto-tech-gear/articles/sd54wav0p2qfxulevbsf.jpg'

export const explodedView = {
  teal: evTeal,
  blue: evBlue,
  black: evBlack,
  pink: evPink,
}
const bottleColors = {
  teal: 'teal',
  blue: 'blue',
  black: 'black',
  pink: 'pink',
}

type SectionType = {
  title: string
  subsections?: SubsectionType[]
  content?: JSX.Element[]
  image?: string
}

type SubsectionType = {
  title: string
  image: string
  content: JSX.Element[]
}

type ArticleContentType = {
  title: string
  subtitle?: string
  date: JSX.Element
  introduction: JSX.Element
  sections: SectionType[]
  sources: JSX.Element[]
}

const Article = (props) => {
  const articleContent: ArticleContentType = {
    title: 'How to Increase Your Energy Naturally',
    subtitle: '4 Culprits Behind Daily Fatigue and 5 Energy Boosters for Your Life',
    date: (
      <p>
        December 10<sup>th</sup>, 2023
      </p>
    ),
    introduction: (
      <p>
        Feeling perpetually tired can feel like a heavy fog clouding your day-to-day life. The good
        news? It's often within your power to chase away this fog and reclaim your energy.
        <br />
        <br /> This article delves into the common causes of tiredness and offers practical
        solutions to help you feel more invigorated and lively.
      </p>
    ),
    sections: [
      {
        title: 'What Makes You Feel Tired?',
        subsections: [
          {
            title: '1. Poor Diet Choices: The Truth About Sugar',
            image: 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218840/toronto-tech-gear/articles/trdcjwufmfbykiwuwtso.jpg',
            content: [
              <p>
                Sugar can be a double edged sword when it comes to providing energy. Eating a diet
                high in sugar and processed foods can cause <strong>blood sugar </strong>levels to{' '}
                <strong>spike and crash, leaving you exhausted</strong> [1].
              </p>,
              <p>
                Similarly, <strong>irregular meal times</strong> and a lack of essential nutrients
                like iron and vitamin B12 can lead to sustained weariness [2-3].
              </p>,
            ],
          },
          {
            title: '2. Sedentary Lifestyle: The Modern Pandemic',
            content: [
              <p>
                Our bodies were not designed to sit on a desk most of our waking hours. A lack of
                physical activity can lead to decreased stamina, muscle weakness, and overall
                fatigue [6].{' '}
              </p>,
              <p>
                Prolonged <strong>sitting or inactivity</strong> can lead to decreased blood flow
                throughout the body. This reduction can
                <strong>slow the delivery of oxygen and nutrients</strong> to the body&#x27;s cells,
                which is vital for maintaining energy levels. [A-C]
              </p>,
            ],
            image: 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218839/toronto-tech-gear/articles/otl38hh4rau19b4ajns5.png',
          },
          {
            title: '3. Dehydration: A Common Cause',
            content: [
              <p>
                Even mild dehydration can affect your mood, concentration, and energy levels [5].
                Water is vital for various bodily functions, including nutrient transport and body
                temperature regulation.{' '}
              </p>,
              <p>
                {' '}
                Studies show that moderate dehydration can lead to headaches, concentration
                problems, and fatigue, as well as affect your mood, causing irritability and
                anxiety.
              </p>,
            ],
            image: 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218839/toronto-tech-gear/articles/c5fp37aaveuswhqmw8of.png',
          },
          {
            title: '4. Inadequate Sleep: So Obvious But So Important',
            content: [
              <p>
                Quality sleep is the cornerstone of good health. Lack of sleep or irregular sleep
                patterns can severely impair cognitive functions and physical health, leading to a
                feeling of constant tiredness
              </p>,
              <p>
                Adults typically need 7-9 hours of sleep per night for optimal health, but you
                should find what’s best for you [4].
              </p>,
              <p>
                In the next section we tell you
                <strong>how to improve your sleep beyond just getting enough hours.</strong>
              </p>,
            ],
            image: 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218838/toronto-tech-gear/articles/vl3deh9apmwrxufwuecu.png',
          },
        ],
      },
      {
        title: 'How Can I Feel More Energized',
        subsections: [
          {
            title: '1. Nourish Your Body: Your Fuel',
            content: [
              <p>
                Eating a diet rich in
                <strong>fruits, vegetables, whole grains, and lean proteins </strong>can provide
                sustained energy. A balanced diet helps in maintaining stable blood sugar levels and
                energy throughout the day [7-8].
              </p>,
              <p>Whenever possible, avoid the highs and lows of sugar and caffeine.</p>,
            ],
            image: 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218840/toronto-tech-gear/articles/hb501cpiu4zqabepmcwo.jpg',
          },
          {
            title: '2. Prioritize Sleep: The Foundation of Recovery',
            content: [
              <p>
                Aim for 7-9 hours of quality sleep per night. Establish a regular sleep routine,
                create a restful environment, and avoid screens before bedtime to improve sleep
                quality. Studies suggest it is best to{' '}
                <strong>sleep in a room 16 - 20° C [9-11].</strong>
              </p>,
              <p>
                Keeping a consistent sleep schedule and eliminating caffeine after 3 p.m. can
                enhance sleep quality and, in turn, energy levels.
              </p>,
            ],
            image: 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218838/toronto-tech-gear/articles/zeatugpniarp60wz1vnr.png',
          },
          {
            title: '3. Stay Hydrated: All-day',
            content: [
              <p id='e7b46995-d02f-4cd2-9e98-9df7f2cd6412'>
                <strong>Drink plenty of water throughout the day.</strong> Keeping hydrated helps
                your body perform at its best, contributing to better energy levels. Even small dips
                in hydration can affect your metabolism.
              </p>,
              <p id='00a4c574-def9-47a7-8ed0-12e7664f93e7'>
                If you are feeling foggy or have a mild headache,
                <strong>make it an instinct to drink a glass of water.</strong>
              </p>,
            ],
            image: 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218837/toronto-tech-gear/articles/gvy8hskfprtfdtdspbje.jpg',
          },
          {
            title: '4. Manage Stress: Actively',
            content: [
              <p id='9906c6c3-ae1c-4515-ae30-478d4e2427cc'>
                Incorporate stress-reduction techniques such as
                <strong>meditation, deep breathing, or going for walks </strong>into your daily
                routine. Managing stress can significantly improve your energy levels and overall
                well-being [12].
              </p>,
              <p id='3b4f54d8-c5e5-483e-b3f0-01f2bc194032'>
                <strong>Walking can be</strong> particularly beneficial because you are getting,
                fresh air, physical activity, and a moment of meditation{' '}
                <strong>all in one.</strong>
              </p>,
            ],
            image: 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218837/toronto-tech-gear/articles/rdunlbikiggbpdvo2bq2.jpg',
          },
          {
            title: '5. Exercise Regularly: Everything Counts',
            content: [
              <p id='c5cb71af-7cc9-40fc-a33e-52daf639d3f7'>
                Engage in both aerobic (like jogging or cycling) and anaerobic (such as weight
                training) exercises. The World Health Organization recommends a{' '}
                <strong>minimum</strong> of
                <strong>150 minutes</strong> of exercise <strong>per week.</strong>
              </p>,
              <p id='f0ffb6a5-fc52-4f60-97af-e8c242c4b878'>
                Regular physical activity<strong> increases stamina, improves sleep</strong>, and
                boosts endorphin levels, making you feel more energized. It also boosts heart health
                and circulation.
              </p>,
              <p id='6f8d08ca-67af-4a88-aa1d-13fd9015f055'>
                Physical activity{' '}
                <strong>sends oxygen and nutrients to your body&#x27;s cells</strong>, helping your
                heart and lungs work more efficiently.
              </p>,
            ],
            image: 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218837/toronto-tech-gear/articles/uifiylfmyipcsjw2o70t.jpg',
          },
        ],
      },
      {
        title: 'Bringing It All Together',
        subsections:[
          {
            title:'',
            content: [
              <p id='c509346e-5749-42db-8340-75ef8688f1d1'>
                Combating tiredness is about making mindful choices in your daily life. By addressing
                the factors that contribute to fatigue and adopting habits that enhance energy, you can
                break the cycle of tiredness and embrace a more vibrant, energetic lifestyle.
              </p>,
              <p id='f97becde-5ce3-458b-832e-b3ee47a8d868'>
                Remember, small changes can lead to significant improvements. Start by adjusting one
                aspect of your life, such as improving your diet or establishing a regular exercise
                routine, and gradually incorporate more changes. With time and consistency, you&#x27;ll
                notice a marked difference in your energy levels and overall quality of life.
              </p>,
            ],
            image:'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218837/toronto-tech-gear/articles/evd89qajyy7donsce2lu.png'
          }
        ],
        image: '',
      },
    ],
    sources: [
      <p id='aab5a4f0-9d2e-42d2-8d92-662d976679fc'>
        (1) Harvard T.H. Chan School of Public Health. &quot;The Nutrition Source -
        Carbohydrates.&quot; Available:
        <p>
          https://www.hsph.harvard.edu/nutritionsource/carbohydrates/
        </p>
      </p>,
      <p id='35308e00-217b-46c5-832a-570ae684cc07'>
        (2) National Institutes of Health (NIH). &quot;Iron Deficiency Anemia.&quot; Available:
        <p>
          https://www.nhlbi.nih.gov/health-topics/iron-deficiency-anemia
        </p>
      </p>,
      <p id='18af9172-74c3-400e-8acc-a42122842112'>
        (3) National Institutes of Health (NIH). &quot;Vitamin B12 Dietary Supplement Fact
        Sheet.&quot; Available:
        <p>
          https://ods.od.nih.gov/factsheets/VitaminB12-HealthProfessional/
        </p>
      </p>,
      <p id='b8e35a26-3cef-4aff-b2f7-842b01e75bb3'>
        (6) Mayo Clinic. &quot;Exercise: 7 benefits of regular physical activity.&quot; Available:
        <p>
          https://www.mayoclinic.org/healthy-lifestyle/fitness/in-depth/exercise/art-20048389
        </p>
      </p>,
      <p id='be18d922-52ca-4586-9094-c2cf40b677d8'>
        (A) Orlin Cohen. &quot;The Risks of Prolonged Sitting (How to Reduce the Side Effects of
        Sitting All Day).&quot; Available:
        <p>
          https://www.orlincohen.com/the-risks-of-prolonged-sitting/
        </p>
      </p>,
      <p id='8620df8b-8467-465f-b008-67d49cb3949e'>
        (B) Mayo Clinic. &quot;Sitting risks: How harmful is too much sitting?&quot; Available:
        <p>
          https://www.mayoclinic.org/healthy-lifestyle/adult-health/expert-answers/sitting/faq-20058005
        </p>
      </p>,
      <p id='014b27e7-2597-4ca9-9b5b-35a7b2e2916d'>
        (C) Yale Medicine. &quot;Why Is Sitting so Bad for Us?&quot; Available:
        <p>
          https://www.yalemedicine.org/news/sitting-health-risks
        </p>
      </p>,
      <p id='683155da-1aaa-4034-b235-c40f4f792234'>
        (D) British Journal of Nutrition. &quot;Effects of hydration status on cognitive performance
        and mood.&quot; Available:
        <p>
          https://www.cambridge.org/core/journals/british-journal-of-nutrition/article/effects-of-hydration-status-on-cognitive-performance-and-mood/88D6ED0C7C802B0E5A9B3F99E9005F4E
        </p>
      </p>,
      <p id='703c1e98-5537-48a0-92d7-5458824dd62a'>
        (4) National Sleep Foundation. &quot;How Much Sleep Do We Really Need?&quot; Available:
        <p>
          https://www.sleepfoundation.org/how-sleep-works/how-much-sleep-do-we-really-need
        </p>
      </p>,
      <p id='e14af1f9-b901-4202-a494-cc4faf26e38d'>
        (5) National Center for Biotechnology Information (NCBI). &quot;Dehydration affects brain
        structure and function in healthy adolescents.&quot; Available:
        <p>
          https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4207053/
        </p>
      </p>,
      <p id='4bf8bc10-14b7-4739-af20-1aaf1bb6d734'>
        (7) Pasiakos, S. M., et al. (2020). Effects of Protein Intake and Resistance Training on
        Muscle Mass During Prolonged Caloric Deficit. Journal of Applied Physiology, 129(2),
        250-259. Available:
        <p>
          https://doi.org/10.1152/japplphysiol.01110.2019
        </p>
      </p>,
      <p id='6b8e089e-94c7-4909-8316-5ebb597c7551'>
        (8) U.S. Department of Agriculture (USDA). &quot;Choose MyPlate: Food Groups.&quot;
        Available:
        <p>
          https://www.choosemyplate.gov/eathealthy/vegetables
        </p>
      </p>,
      <p id='04ea5f6f-8a2b-4f45-9700-26d20741ae14'>
        (9) Sleep Foundation. (2023) &quot;Best Temperature for Sleep&quot;. Available:
        <p>
          https://www.sleepfoundation.org/bedroom-environment/best-temperature-for-sleep#:~:text=The
          best room temperature for,for the most comfortable sleep
        </p>
      </p>,
      <p id='d43a6cb9-86d7-4b91-a735-d1bda2233796'>
        (10) National Sleep Foundation. &quot;Healthy Sleep Tips.&quot; Available:
        <p>
          https://www.sleepfoundation.org/sleep-hygiene/healthy-sleep-tips
        </p>
      </p>,
      <p id='a2e7d9bf-45ca-4df3-96a2-54f6f0b71ba5'>
        (11) National Sleep Foundation. &quot;How Temperature Affects Sleep.&quot; Available:
        <p>
          https://www.sleepfoundation.org/bedroom-environment/touch/how-temperature-affects-sleep
        </p>
      </p>,
      <p id='ed4c2c0e-33ed-43cc-bd0e-053ece2a1d18'>
        (12) Mayo Clinic. &quot;Meditation: A simple, fast way to reduce stress.&quot; Available:
        <p>
          https://www.mayoclinic.org/tests-procedures/meditation/in-depth/meditation/art-20045858
        </p>
      </p>,
    ],
  }

  const [sbottle, setSBottle] = useState(bottleColors.black)
  const [buttonColor, setButtonColor] = useState('bg-gray-600')

  const learnMoreLink = props.learnMoreLink
    ? props.learnMoreLink
    : 'https://www.torontotechgear.com/?source=hydration-boost-nutrition-article'
  console.log(learnMoreLink)

  const imageStyle: any = {
    aspectRatio: '3/2',
    objectFit: 'cover',
    display: 'flex',
    width: '100%',
  }
  return (
    <div className='article-container'>
      <Helmet>
        <title>
          How to Increase Energy Levels Naturally | Stop Feeling Tired - Toronto Tech Gear
        </title>
        <meta
          name='description'
          content='This article delves into common causes for feeling tired and offers strategies and solutions to help you feel more energized and lively.'
        />
      </Helmet>
      <ExternalHeader
        nextArticleLink='/exercise-mental-health-article'
        nextArticleTitle='Exercise for Mental Health'
        ttglogo={true}
        productLink={learnMoreLink}
      />
      <div className='body-padding'>
        <div id='title'>
          <h1 className='mb-4 mt-8 leading-10'>
            {articleContent.title}: {articleContent.subtitle}
          </h1>
          {articleContent.date}
        </div>
        <Container fluid>
          <Row>
            <Col className='mt-4' xl={8} md={12} sm={12}>
              <img style={imageStyle} alt='header' src={HeaderImage} />
            </Col>
            <Col className='mt-4'>{articleContent.introduction}</Col>
          </Row>
        </Container>
        {articleContent.sections.map((sec, index) => (
          <div key={sec.title} className='mb-8'>
            <h2 className='xs:pt-0 sm:pt-12 w-full text-left'>{sec.title}</h2>
            {sec.subsections?.map((sub, index) => (
              <div>
                <h3 className='xs:pt-0 sm:pt-10'>{sub.title}</h3>
                <Container key={sub.title}>
                  <Row>
                    <Col xl={8} md={12} sm={12}>
                      <img
                        className='mt-2'
                        style={imageStyle as any}
                        alt={sub.title}
                        src={sub.image}
                      />
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingTop: '1rem',
                        }}>
                        {sub.content}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            ))}
          </div>
        ))}
        <div className='pb-0'></div>
        <h1 className='mt-8'>We Are Here to Help</h1>
        <p>
          Toronto Tech is on a mission to improve people’s wellbeing through nutrition, hydration,
          and exercise. That is why we have created the perfect gear to help you stay hydrated and
          active wherever you go.
        </p>
        <div className='mt-8 items-center flex-col justify-center gap-10 md:mt-4 md:flex'>
          {/* GRAPHIC */}
          <div className='flex items-center justify-center gap-2 '>
            <ColorButton
              onClick={() => {
                setButtonColor('bg-blueBottle')
                setSBottle(bottleColors.blue)
              }}
              color='bg-blueBottle'
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-gray-600')
                setSBottle(bottleColors.black)
              }}
              color='bg-blackBottle'
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-tealDark')
                setSBottle(bottleColors.teal)
              }}
              color='bg-tealBottle'
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-pinkDark')
                setSBottle(bottleColors.pink)
              }}
              color='bg-pinkBottle'
            />
          </div>
          <img
            style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '80%' }}
            alt='benefits-page-graphic'
            src={explodedView[sbottle]}
            className='mb-2 md:mb-0'
          />
          {/* DESCRIPTION */}
          <div
            style={{ textDecoration: 'none' }}
            className='w-full  items-center justify-center flex md:mt-0 xs:mt-4'>
            <ActionButton color={buttonColor} href={learnMoreLink} target='_blank'>
              Learn More
            </ActionButton>
          </div>
        </div>
        <div className='mt-10 mb-10'>
          <h1>Follow us</h1>
          <SocialMediaIcons />
        </div>

        <h2>Sources:</h2>
        <div className='break-words w-full'>
        {articleContent.sources?.map((source, index) => (
          <p className='break-words' key={index}>{source}</p>
        ))}
        </div>
        <Footer/>
      </div>
    </div>
  )
}

export default Article
