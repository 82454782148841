import React from 'react';
import './article.css';

import evTeal from '../../assets/exploded-teal.png';
import evBlue from '../../assets/exploded-blue.png';
import evBlack from '../../assets/exploded-black.png';
import evPink from '../../assets/exploded-pink.png';

import ExternalHeader from '../../components/ExternalHeader';
import { Helmet } from 'react-helmet-async';
import { ArticlesList } from '../../ArticlesList.js';
import ArticleCard from '../../components/ArticleCard';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from '../../components/Footer.js'

export const explodedView = {
  teal: evTeal,
  blue: evBlue,
  black: evBlack,
  pink: evPink,
};
const bottleColors = {
  teal: 'teal',
  blue: 'blue',
  black: 'black',
  pink: 'pink',
};

const Articles = (props) => {
  const imageStyle = {
    aspectRatio: '3/2',
    objectFit: 'cover',
    display: 'flex',
    width: '100%',
  };
  return (
    <div className="article-container bg-white">
      <Helmet>
        <title>
          Toronto Tech Gear - Movement & Fitness Articles
        </title>
        <meta
          name="description"
          content=" Physical health has often been closely tied to exercise.
                However, the mental health implications of physical activity, or
                lack thereof, are equally important but often overlooked."
        />
      </Helmet>
      <ExternalHeader
        ttglogo={true}
      />
      <div className="body-padding pt-32">
        <Container className='grid md:grid-cols-1' fluid>
        <Row className='gap-4 justify-center'>
          {ArticlesList.map((a)=>(
            <ArticleCard image={a.image} title={a.title} route={a.route} date={a.date} />
          ))}
          </Row>
        </Container>
        <Footer/>
      </div>
    </div>
  );
};

export default Articles;
