import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';
import { Container, Nav} from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

const ExternalHeader = (props) => {
  return (
    <header >
      <Navbar  id='custom-nav' collapseOnSelect expand='lg' bg="black" variant="dark">
      <Container fluid style={{width:'90%'}}>
          <LinkContainer to="/">
            {props.ttglogo? <Navbar.Brand><img width='150' src='images/TT_GEAR-LOGO-white.png' alt='TT Logo'/></Navbar.Brand>
            :<Navbar.Brand><img width='150' src='images/TORONTOTECH_LOGO_TRANSPARENT.png' alt='TT Logo'/></Navbar.Brand>}
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav'/>
          <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto w-100 justify-content-end'>
          {props.nextArticleLink &&<HashLink to='/articles'  className="nav-link mr-4">
              <span style={{fontSize:20}}>More Quick Reads</span>
            </HashLink>}
          
          <HashLink to={props.productLink?props.productLink:'https://www.torontotechgear.com/?source=to-tech-website'} className="nav-link">
              <span style={{fontSize:20}}>Store</span>
            </HashLink>
            
          </Nav>
          </Navbar.Collapse>
          </Container>
      </Navbar>
    </header>
  )
}

export default ExternalHeader