import React, { useEffect, useState } from 'react';
import './article.css';
import ColorButton from '../../components/ColorButton';
import ActionButton from '../../components/ActionButton';
import { Row, Col, Container } from 'react-bootstrap';
import Footer from '../../components/Footer';

import evTeal from '../../assets/exploded-teal.png';
import evBlue from '../../assets/exploded-blue.png';
import evBlack from '../../assets/exploded-black.png';
import evPink from '../../assets/exploded-pink.png';
import HeaderImage from '../../assets/exercise-article/header.jpeg';
import b1 from '../../assets/exercise-article/benefit1.jpeg';
import b2 from '../../assets/exercise-article/benefit2.jpeg';
import b3 from '../../assets/exercise-article/benefit3.jpeg';
import FooterImage from '../../assets/exercise-article/footer.jpg';

import r1 from '../../assets/exercise-article/risk1.jpeg';
import r2 from '../../assets/exercise-article/risk2.jpeg';
import r3 from '../../assets/exercise-article/risk3.jpeg';
import ExternalHeader from '../../components/ExternalHeader';
import SocialMediaIcons from '../../components/SocialMediaIcons';
import { Helmet } from 'react-helmet-async';

export const explodedView = {
  teal: evTeal,
  blue: evBlue,
  black: evBlack,
  pink: evPink,
};
const bottleColors = {
  teal: 'teal',
  blue: 'blue',
  black: 'black',
  pink: 'pink',
};



const WaterArticle = (props) => {
  const [sbottle, setSBottle] = useState(bottleColors.black);
  const [buttonColor, setButtonColor] = useState('bg-gray-600');
  
  const learnMoreLink = props.learnMoreLink?props.learnMoreLink:'https://www.torontotechgear.com/?source=exercise-article'
  console.log(learnMoreLink)
  const risks = [
    {
      title: `1. Increased Risk of Depression`,
      description: [
        `According to a study published in the Journal of Adolescent Health in 2021, inactive adolescents tend to have higher levels of depressive symptoms compared to their physically active peers.`,
        ` The researchers suggested that sedentary activities, such as excessive screen time, might lead to social isolation and disrupt sleep patterns, both known contributors to depressive symptoms (1).`,
      ],
      image: r1,
    },
    {
      title: `2. Heightened Anxiety and Stress`,
      description: [
        `Leading a sedentary lifestyle could heighten levels of anxiety and stress, as revealed in a study from the International Journal of Environmental Research and Public Health.`,
        ` The research suggested that a lack of physical activity might reduce the body's ability to respond to stress, leading to elevated levels of stress hormones and triggering anxiety disorders (2).`,
      ],
      image: r2,
    },
    {
      title: `3. Cognitive Decline`,
      description: [
        `Physical inactivity has been associated with cognitive decline, especially in older adults.`,
        ` A study published in Frontiers in Aging Neuroscience in 2022 found that older adults who lead a sedentary lifestyle had significantly worse cognitive performance, including problems with memory, attention, and decision-making skills, compared to their active counterparts (3).`,
      ],
      image: r3,
    },
  ];

  const benefits = [
    {
      title: `1. Prevention of Depression`,
      description: [
        `Exercise can act as a protective measure against the onset of depression. A 2022 study published in The Lancet Psychiatry suggested that even modest increases in physical activity, such as daily walking or cycling, could significantly reduce the risk of depression. `,
        `The researchers theorized that physical activity might enhance the brain's resilience to stress, thereby preventing the onset of depressive symptoms (4).`,
      ],
      image: b1,
    },
    {
      title: `2. Alleviation of Anxiety: `,
      description: [
        `
      Regular physical activity has been recognized as an effective strategy for preventing and managing anxiety disorders.`,
        ` A 2023 study in the Journal of Anxiety Disorders reported that exercise could regulate the body's stress response and promote the release of endorphins, neurotransmitters known for their mood-boosting properties, thereby mitigating anxiety symptoms (5).`,
      ],
      image: b2,
    },
    {
      title: `3. Improved Cognitive Functioning`,
      description: [
        `Physical activity has been associated with enhanced cognitive performance. `,
        `According to a 2022 study in the British Journal of Sports Medicine, individuals who regularly engaged in exercise exhibited improved memory, attention, and cognitive processing speed.`,
        ` The study posited that physical activity could improve blood flow to the brain, stimulate neurogenesis, and promote cognitive flexibility (6).`,
      ],
      image: b3,
    },
  ];


  const imageStyle = {
    aspectRatio: '3/2',
    objectFit: 'cover',
    display: 'flex',
    width: '100%',
  };
  return (
    <div className="article-container">
      <Helmet>
        <title>
          The Importance of Exercise for Mental Health - Toronto Tech Gear
        </title>
        <meta
          name="description"
          content=" Physical health has often been closely tied to exercise.
                However, the mental health implications of physical activity, or
                lack thereof, are equally important but often overlooked."
        />
      </Helmet>
      <ExternalHeader
        nextArticleLink="/health-risks-benefits-from-drinking-enough-water"
        nextArticleTitle="Why Drinking Water is Important"
        ttglogo={true}
        productLink={learnMoreLink}
      />
      <div className="body-padding">
        <div id="title">
          <h1 className="mb-4 mt-8">
            The Importance of Exercise for Mental Health: 6 Independent Studies,
            3 Similar Findings, and 1 Conclusion
          </h1>
          <p>August 1<sup>st</sup>, 2023</p>
        </div>
        <Container fluid>
          <Row>
            <Col className="mt-4" xl={8} md={12} sm={12}>
              <img style={imageStyle} alt="header" src={HeaderImage} />
            </Col>
            <Col className="mt-4">
              <p style={{ display: 'flex' }}>
                Physical health has often been closely tied to exercise.
                However, the mental health implications of physical activity, or
                lack thereof, are equally important but often overlooked.
                <br />
                <br /> Here, we delve into the impacts of both a sedentary
                lifestyle and an active one, each presenting starkly contrasting
                effects on mental health.
              </p>
            </Col>
          </Row>
        </Container>
        <h1 className="mt-16">Negative Effects of a Sedentary Lifestyle:</h1>
        {risks.map((r, index) => (
          <div className="mb-8">
            <h2 style={{ paddingTop: '3rem' }}>{r.title}</h2>
            <Container>
              <Row>
                <Col xl={8} md={12} sm={12}>
                  <img
                    className="mt-2"
                    style={imageStyle}
                    alt={index.toString() + '-b'}
                    src={r.image}
                  />
                </Col>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '1rem',
                    }}
                  >
                    {r.description.map((item) => (
                      <p>{item}</p>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ))}
        <h1 style={{ paddingTop: '3rem' }}>
          Positive Effects of an Active Lifestyle
        </h1>
        {benefits.map((r, index) => (
          <div className="mb-8">
            <h2>{r.title}</h2>
            <Container>
              <Row>
                <Col xl={8} md={12} sm={12}>
                  <img
                    className="mt-2"
                    style={imageStyle}
                    alt={index.toString() + '-b'}
                    src={r.image}
                  />
                </Col>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '1rem',
                    }}
                  >
                    {r.description.map((item) => (
                      <p>{item}</p>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ))}
        <div className="pb-2"></div>
        <div>
          <p>
            Understanding the negative implications of a sedentary lifestyle and
            the positive influences of an active one underscores the importance
            of incorporating physical activity into our daily routines.{' '}
            <strong>
              The World Health Organization recommends a minimum of 150 minutes
              of moderate-intensity or 75 minutes of vigorous-intensity physical
              activity per week
            </strong>
            , emphasizing that even minor increases in physical activity can
            lead to substantial mental health improvements.
            <br />
            <br />
            Exercise should be viewed not as a burdensome task but an enjoyable
            activity that yields significant mental health benefits.{' '}
            <strong>
              Discovering a physical activity that you enjoy can foster
              commitment to regular exercise.
            </strong>{' '}
            Before embarking on a new exercise regimen, it's crucial to consult
            with a healthcare professional.
            <br />
            <br /> In conclusion, while a sedentary lifestyle can have
            deleterious effects on mental health, adopting an active lifestyle
            can counteract these effects and significantly improve mental
            well-being. Exercise benefits extend beyond the physical,
            contributing to mental resilience and cognitive prowess. <br />
            <br />
            By incorporating more movement into our daily routines, we can
            counteract the pitfalls of sedentary behavior and bolster our mental
            well-being. Let's pledge to sit less, move more, and recognize
            physical activity as a cornerstone of mental health.
          </p>
        </div>
        <img style={imageStyle} alt="header" src={FooterImage} />
        <h1 className="mt-8">We Are Here to Help</h1>
        <p>
          Toronto Tech is on a mission to improve people’s wellbeing through
          nutrition, hydration, and exercise. That is why we have created the
          perfect gear to help you stay hydrated and active wherever you go.
        </p>
        <div className="mt-8 items-center flex-col justify-center gap-10 md:mt-4 md:flex">
          {/* GRAPHIC */}
          <div className="flex items-center justify-center gap-4 ">
            <ColorButton
              onClick={() => {
                setButtonColor('bg-blueBottle');
                setSBottle(bottleColors.blue);
              }}
              color="bg-blueBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-gray-600');
                setSBottle(bottleColors.black);
              }}
              color="bg-blackBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-tealDark');
                setSBottle(bottleColors.teal);
              }}
              color="bg-tealBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-pinkDark');
                setSBottle(bottleColors.pink);
              }}
              color="bg-pinkBottle"
            />
          </div>
          <img
            style={{ objectFit: 'cover', maxWidth: '100%', maxHeight: '80%' }}
            alt="benefits-page-graphic"
            src={explodedView[sbottle]}
            className="mb-2 md:mb-0"
          />
          {/* DESCRIPTION */}
          <div
            style={{ textDecoration: 'none' }}
            className="w-full  items-center justify-center flex md:mt-0 xs:mt-4"
          >
            <ActionButton
              color={buttonColor}
              href={learnMoreLink}
              target="_blank"
            >
              Learn More
            </ActionButton>
          </div>
        </div>
        <div className="mt-10 mb-10">
          <h1>Follow us</h1>
          <SocialMediaIcons />
        </div>

        <h2>Sources:</h2>
        <p>
          (1) Sampasa-Kanyinga, H., et al. (2021). Journal of Adolescent Health,
          68(2), 334–341. Available:
          <p className="source">
            https://doi.org/10.1016/j.jadohealth.2020.06.039
          </p>
        </p>

        <p>
          (2) Opdal, I. M., et al. (2021). International Journal of
          Environmental Research and Public Health, 18(5), 2553. Available:
          <p className="source">https://doi.org/10.3390/ijerph18052553</p>
        </p>

        <p>
          (3) Diaz, K. M., et al. (2022). Frontiers in Aging Neuroscience, 14,
          747997. Available:
          <p className="source">https://doi.org/10.3389/fnagi.2022.747997</p>
        </p>

        <p>
          (4) Harvey, S. B., et al. (2022). The Lancet Psychiatry, 9(3),
          175–182. Available:
          <p className="source">
            https://doi.org/10.1016/S2215-0366(22)30006-7
          </p>
        </p>

        <p>
          (5) Anderson, E., & Shivakumar, G. (2023). Journal of Anxiety
          Disorders, 74, 102372. Available:
          <p className="source">
            https://doi.org/10.1016/j.janxdis.2023.102372
          </p>
        </p>

        <p>
          (6) Northey, J. M., et al. (2022). British Journal of Sports Medicine,
          56(10), 601–605. Available:
          <p className="source">https://doi.org/10.1136/bjsports-2016-096587</p>
        </p>
        <Footer/>
      </div>
    </div>
  );
};

export default WaterArticle;
