import { motion } from "framer-motion";
import React from 'react';


  
const ColorButton = ({ color,onClick })  => {
  const buttonVariants = {
    initial: {
      scale: 1,
    },
    hover: {
      scale: 1.2,
    },
    animate: {
      scale: [1, 1.2, 1],
      transition: {
        duration: 0.3,
        delay: 0.5,
        repat:0
      },
    },
  };

  return (
    <motion.div
      initial="initial"
      whileInView="animate"
      whileHover="hover"
      variants={buttonVariants}
      
    >
        <button onClick={onClick} className={`h-20 w-20 rounded-full border-2 border-gray-10 drop-shadow-dark ${color}`}/>
    </motion.div>
  );
};

export default ColorButton