import React, { useState } from 'react'
import './article.css'
import ColorButton from '../../components/ColorButton'
import ActionButton from '../../components/ActionButton'
import { Row, Col, Container } from 'react-bootstrap'
import Footer from '../../components/Footer'

import evTeal from '../../assets/exploded-teal.png'
import evBlue from '../../assets/exploded-blue.png'
import evBlack from '../../assets/exploded-black.png'
import evPink from '../../assets/exploded-pink.png'

import ExternalHeader from '../../components/ExternalHeader'
import SocialMediaIcons from '../../components/SocialMediaIcons'
import { Helmet } from 'react-helmet-async'

const HeaderImage =
  'https://res.cloudinary.com/dgf1xlybh/image/upload/v1709993833/toronto-tech-gear/articles/7_pqssmp.png'

export const explodedView = {
  teal: evTeal,
  blue: evBlue,
  black: evBlack,
  pink: evPink,
}
const bottleColors = {
  teal: 'teal',
  blue: 'blue',
  black: 'black',
  pink: 'pink',
}

type SectionType = {
  title: string
  subsections?: SubsectionType[]
  content?: JSX.Element[]
  image?: string
}

type SubsectionType = {
  title: string
  image: string
  content: JSX.Element[]
}

type ArticleContentType = {
  title: string
  subtitle?: string
  date: JSX.Element
  introduction: JSX.Element
  sections: SectionType[]
  sources: JSX.Element[]
}

const Article = (props) => {
  const articleContent: ArticleContentType = {
    title: 'The Lies Behind No Pain, No Gain',
    subtitle: 'And 3 takeaways to optimize muscle growth',
    date: (
      <p>
        March 3<sup>rd</sup>, 2023
      </p>
    ),
    introduction: (
      <div>
        <p id='dc5f017c-15ce-4c3b-8808-24c1e53790ea'>
          Embarking on a journey to sculpt the <i>perfect physique</i>, many of us have been guided by the age-old mantra, 
          "No pain, no gain." <br/><br/>This axiom, deeply ingrained in the ethos of fitness culture, suggests a direct correlation between the soreness 
          of our muscles and the effectiveness of our workouts. But what if this foundational belief is <strong>more myth than truth?</strong>
        </p>
      </div>
    ),
    sections: [
      {
        title: null,
        subsections: [
          {
            title: 'Why do Muscles Grow?',
            image:
              'https://res.cloudinary.com/dgf1xlybh/image/upload/v1709993833/toronto-tech-gear/articles/8_cjscop.png',
            content: [
              <p id='77583438-bc80-42bd-b375-a6c8ad2cfb5a'>
                Muscles have a property called <i>phenotypic plasticity</i>, which allows them to <strong>adapt to the nature and intensity of their use.</strong> 
                <br/><br/>And because force production is a function of the cross-sectional area (thickness) of the muscle, resistance exercise produces compensatory growth. 
              </p>,
              <p id='2f688c25-a9ac-4602-a6e4-1c9a9ea253b7'>
                Simply put: the more your body thinks you need bigger muscles, the more it makes them grow.
              </p>,
            ],
          },
          {
            title: 'Nature is Wise',
            content: [
              <p id='c52151cd-275c-4994-915c-540e0b68210f'>
                A study published in <i>The Journal of Experimental Biology</i> showed that <strong>muscle growth wass independent of the soreness</strong> experienced by the participants 
                <strong> but proportional to the volume of the exercise.</strong> Here, volume means more weight and more repetitions.
              </p>,
              <p id='898c2f2f-d64f-4689-8bdd-a9560648be90'>
                This is consistent with the accounts of several athletes and fitness coaches, like David Marchante and Jeff Cavaliere, who suggest focusing on the volume of each workout - along with other factors - when aiming for muscle growth. They also generally suggest avoiding soreness and injury rather than aiming for it. 
                This is mostly because <strong>soreness reduces your performance in future workouts.</strong>
              </p>,
              <p>
                From an engineering perspective, damage as a necessary precursor for restructuring would be a poor <i>design feature</i>.
                A need for strength to be coupled with a requirement for <strong>damage-induced diminished performance</strong> (linked to soreness) 
                would certainly be <strong>avoided by natural selection if possible.</strong> 
              </p>
            ],
            image:
              'https://res.cloudinary.com/dgf1xlybh/image/upload/v1709993834/toronto-tech-gear/articles/10_rpksi6.png',
          },
          {
            title: 'Pain vs Gain',
            content: [
              <p id='477325e0-58aa-437e-88aa-e4c8d3e350a0'>
                Soreness, or delayed onset muscle soreness (DOMS) is an inflammatory response to tiny tears (micro-trauma) in the connective tissue caused by training. 
                <strong> These micro-trauma are <i>necessary</i> for your muscles to grow.</strong>
              </p>,
              <p id='04443fb9-99a3-409b-a53e-8e958792f89e'>
                <strong>However, DOMS is not always linked to pain.</strong> Why DOMS manifests itself as pain, sometimes, remains unclear. The correlation might be attributed 
                to a form of self-protection mechanism to prevent further damage, as DOMS has been shown to <strong>impair force output</strong> for up to 24 hours following exercise. 
              </p>,
              <p>
                There’s very little evidence to show that being sore correlates to muscle damage or faster muscle growth, 
                or that a lack of soreness means that your workout wasn’t effective.
              </p>,
              <p>
                So, pain is indeed a response to muscle damage and, therefore, linked to muscle regrowth if appropriate recovery is granted.
                But, it is not necessary, and can be <strong>counterproductive in the long run.</strong> 
              </p>
            ],
            image:
              'https://res.cloudinary.com/dgf1xlybh/image/upload/v1709993834/toronto-tech-gear/articles/11_jnjnhh.png',
          },
          {
            title: 'The Bigger Picture',
            content: [
              <p id='3cba4af4-cec7-44ed-ad39-4c01c36c2ffa'>
                There are a lot of factors to consider when aiming for muscle growth. One of them, as mentioned before, is volume (weight times repetitions).
              </p>,
              <p id='3f6714bd-0f71-4f5e-957e-4fc5d8e79643'>
                Moreover, volume not only applies to a single workout. Your total volume over the week matters too. This is why muscle soreness is detrimental. 
                You might go above your target volume one day and develop soreness as a result. Then, this will impact your following 2-3 workouts.
              </p>,
              <p>
                Recovery is another reason to limit the intensity and volume of your workouts. You must allow your body to recover, otherwise your muscles will <strong>not grow. </strong> 
                The time your body takes to recover is also linked to the volume of exercise. If your muscles are sore, you likely need more time to recover.
              </p>,
              <p>
                Also, <strong>what kind of life is it</strong>, if you’re constantly sore? Unable to perform other daily activities. 
                If you’re like most people, you exercise to be healthy and better equipped to give your all in your work and personal life. 
              </p>
            ],
            image:
              'https://res.cloudinary.com/dgf1xlybh/image/upload/v1709993834/toronto-tech-gear/articles/Untitled_q0h9u2.png',
          },
        ],
      },
      {
        title: 'Conclusion',
        subsections: [
          {
            title: '',
            content: [
              <ul style={{listStyleType:'disc'}}>
                <li>Muscle soreness is not a requirement for muscle growth</li>
                <li>Muscle growth depends on several factors including volume and recovery time</li>
                <li>If your muscles are sore, you may want to skip a few workouts to allow your muscles to recover and grow</li>
                <li>Training to be sore is not practical and can negatively impact your performance during workouts and other daily activities</li>
              </ul>,
            ],
            image:
              'https://res.cloudinary.com/dgf1xlybh/image/upload/v1709993834/toronto-tech-gear/articles/Untitled_1_d8prjp.png',
          },
        ],
        image: '',
      },
    ],
    sources: [
      <p>
        (1) Kyle L. Flann, Paul C. LaStayo, Donald A. McClain, Mark Hazel, Stan L. Lindstedt (2011). "Muscle damage and muscle remodeling: no pain, no gain?". Journal of Experimental Biology. DOI:10.1242/jeb.050112 
      </p>,
      <p>(2) Chris Hall (2017). "Is muscle soreness a reliable indicator of a good workout?". Hall Personal Training. Available: https://www.personaltraineroxford.com/-Is-muscle-soreness-a-reliable-indicator-of-a-good-workout</p>,
      <p>
        (3) Eric Arnold (2023). "Should You Always Be Sore After a Workout?". Peloton. Available: https://www.onepeloton.com/blog/if-im-not-sore-am-i-still-building-muscle/
      </p>,
      <p>
        (4) Christine Yu (2014). "No pain, no gain? 5 myths about post-workout muscle soreness". Life by Daily Burn & CNN. Available: https://www.cnn.com/2014/08/04/health/muscle-soreness-myths/index.html
      </p>,
      <p>
        (5) Nic Bartolotta (2023). "Are Sore Muscles A Sign Of Muscle Growth?". Roflex. Available: https://rolflexrecovery.com/blogs/news/sore-muscles-growth
      </p>,
      <p>
        (6) Christian Finn (2018). "Being Sore After a Workout Doesn't Mean Your Muscles Are Growing". Vice. Aavailable: https://www.vice.com/en/article/9k4gvy/being-sore-after-a-workout-doesnt-mean-your-muscles-are-growing
      </p>,
      <p>
        (7) Jamie Millar (2023). "'Should I Workout with Sore Muscles?' We Asked the Experts". MensHealth. Available: https://www.menshealth.com/uk/fitness/a31078515/train-through-doms-sore-muscles/
      </p>,
      <p>
        (8) Weightology (2021). "Training Frequency for Hypertrophy: The Evidence-Based Bible". Weightology. Available: https://weightology.net/the-members-area/evidence-based-guides/training-frequency-for-hypertrophy-the-evidence-based-bible/#:~:text=Overall%2C%20the%20weight%20of%20the,require%204%20days%20or%20more
      </p>
    ],
  }

  const [sbottle, setSBottle] = useState(bottleColors.black)
  const [buttonColor, setButtonColor] = useState('bg-gray-600')

  const learnMoreLink = props.learnMoreLink
    ? props.learnMoreLink
    : 'https://www.torontotechgear.com/?source=hydration-boost-nutrition-article'
  console.log(learnMoreLink)

  const imageStyle: any = {
    aspectRatio: '3/2',
    objectFit: 'cover',
    display: 'flex',
    width: '100%',
  }
  return (
    <div className='article-container'>
      <Helmet>
        <title>
          The Lies Behind No Pain, No Gain & How to Optimize Muscle Growth  - Toronto Tech Gear
        </title>
        <meta
          name='description'
          content='No Pain, No Gain is deeply ingrained in the ethos of fitness but this foundational belief is more myth than truth?'
        />
      </Helmet>
      <ExternalHeader
        nextArticleLink='/exercise-mental-health-article'
        nextArticleTitle='Exercise for Mental Health'
        ttglogo={true}
        productLink={learnMoreLink}
      />
      <div className='body-padding'>
        <div id='title'>
          <h1 className='mb-0 mt-8 leading-10'>
            {articleContent.title}
          </h1>
          <p className='mb-2 text-gray-600 text-xl mt-1'>
          {articleContent.subtitle}
          </p>
          {articleContent.date}
        </div>
        <Container fluid>
          <Row>
            <Col className='mt-4' xl={8} md={8} sm={12}>
              <img style={imageStyle} alt='header' src={HeaderImage} />
            </Col>
            <Col className='mt-4'>{articleContent.introduction}</Col>
          </Row>
        </Container>
        {articleContent.sections.map((sec, index) => (
          <div key={sec.title} className='mb-8'>
            <h2 className='xs:pt-0 sm:pt-12 w-full text-left'>{sec.title}</h2>
            {sec.subsections?.map((sub, index) => (
              <div>
                <h3 className='xs:pt-0 sm:pt-10'>{sub.title}</h3>
                <Container key={sub.title}>
                  <Row>
                    <Col xl={8} md={8} sm={12}>
                      <img
                        className='mt-2'
                        style={imageStyle as any}
                        alt={sub.title}
                        src={sub.image}
                      />
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingTop: '1rem',
                        }}>
                        {sub.content}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            ))}
          </div>
        ))}
        <div className='pb-0'></div>
        <h1 className='mt-8'>We Are Here to Help</h1>
        <p>
          Toronto Tech Gear is on a mission to improve people’s wellbeing through nutrition, hydration,
          and exercise. That is why we have created the perfect gear to help you stay hydrated and
          active wherever you go.
        </p>
        <div className='mt-8 items-center flex-col justify-center gap-10 md:mt-4 md:flex'>
          {/* GRAPHIC */}
          <div className='flex items-center justify-center gap-2 '>
            <ColorButton
              onClick={() => {
                setButtonColor('bg-blueBottle')
                setSBottle(bottleColors.blue)
              }}
              color='bg-blueBottle'
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-gray-600')
                setSBottle(bottleColors.black)
              }}
              color='bg-blackBottle'
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-tealDark')
                setSBottle(bottleColors.teal)
              }}
              color='bg-tealBottle'
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-pinkDark')
                setSBottle(bottleColors.pink)
              }}
              color='bg-pinkBottle'
            />
          </div>
          <img
            style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '80%' }}
            alt='benefits-page-graphic'
            src={explodedView[sbottle]}
            className='mb-2 md:mb-0'
          />
          {/* DESCRIPTION */}
          <div
            style={{ textDecoration: 'none' }}
            className='w-full  items-center justify-center flex md:mt-0 xs:mt-4'>
            <ActionButton color={buttonColor} href={learnMoreLink} target='_blank'>
              Learn More
            </ActionButton>
          </div>
        </div>
        <div className='mt-10 mb-10'>
          <h1>Follow us</h1>
          <SocialMediaIcons />
        </div>

        <h2>Sources:</h2>
        <div className='break-words w-full'>
          {articleContent.sources?.map((source, index) => (
            <p className='break-words' key={index}>
              {source}
            </p>
          ))}
        </div>
        < Footer/>
      </div>
    </div>
  )
}

export default Article
