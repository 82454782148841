import React, { useState } from 'react';
import './article.css';
import ColorButton from '../../components/ColorButton';
import ActionButton from '../../components/ActionButton';
import { Row, Col, Container } from 'react-bootstrap';
import Footer from '../../components/Footer';

import evTeal from '../../assets/exploded-teal.png';
import evBlue from '../../assets/exploded-blue.png';
import evBlack from '../../assets/exploded-black.png';
import evPink from '../../assets/exploded-pink.png';
import HeaderImage from '../../assets/water-article/header.png';
import FooterImage from '../../assets/water-article/footer.jpg';
import b1 from '../../assets/water-article/benefit1.jpg';
import b2 from '../../assets/water-article/benefit2.jpg';
import b3 from '../../assets/water-article/benefit3.jpg';
import b4 from '../../assets/water-article/benefit4.jpg';
import b5 from '../../assets/water-article/benefit5.jpg';

import r1 from '../../assets/water-article/risk1.jpg';
import r2 from '../../assets/water-article/risk2.jpg';
import r3 from '../../assets/water-article/risk3.jpg';
import r4 from '../../assets/water-article/risk4.jpg';
import r5 from '../../assets/water-article/risk5.jpg';
import ExternalHeader from '../../components/ExternalHeader';
import SocialMediaIcons from '../../components/SocialMediaIcons';
import { Helmet } from 'react-helmet-async';

export const explodedView = {
  teal: evTeal,
  blue: evBlue,
  black: evBlack,
  pink: evPink,
};
const bottleColors = {
  teal: 'teal',
  blue: 'blue',
  black: 'black',
  pink: 'pink',
};

const learnMoreLink = 'https://bottle.to-tech.ca/';


const WaterArticle = () => {
  const [sbottle, setSBottle] = useState(bottleColors.black);
  const [buttonColor, setButtonColor] = useState('bg-gray-600');

  const risks = [
    {
      title: `1. Unclear Thinking, Fatigue, and Mood Changes`,
      description: [`These are the direct consequences of dehydration. Dehydration can
      impair physical performance and concentration, hindering our ability
      to perform at our best.`,`
      Additionally, when our bodies lack sufficient
      hydration, it can lead to a range of symptoms, including dry mouth,
      fatigue, and dizziness.`],
      image: r1,
    },
    {
      title: `2. Impaired Physical Health`,
      description: [`Inadequate water intake can have negative consequences for our physical health. Without proper hydration, our bodies struggle to regulate body temperature, leading to overheating and an increased risk of heat-related illnesses.`,
      `Additionally, dehydration can impact digestion, causing constipation and hindering nutrient absorption, which can have a cascading effect on our overall well-being.`],
      image: r2,
    },
    {
      title: `3. Reduced Kidney Function`,
      description: [`Our kidneys play a crucial role in filtering waste and toxins from our bodies.`,
      ` When we don't consume enough water, our kidneys have to work harder to eliminate waste, which can strain their function over time. This strain may contribute to the development of kidney stones and urinary tract infections.`],
      image: r3,
    },
    {
      title: `4. Headaches`,
      description: [`Dehydration has been linked to an increased risk of headaches and migraines.`,
      ` When our bodies lack adequate fluid levels, blood flow to the brain decreases, leading to dilated blood vessels and triggering painful headaches. Ensuring proper hydration can significantly reduce the frequency and intensity of these headaches.`],
      image: r4,
    },
    {
      title: `5. Poor Mental Health`,
      description: [`While the effects of dehydration on mental health may not be as widely discussed, they are no less significant.`,
      ` Research suggests that even mild dehydration can negatively affect our mood, increase anxiety levels, and impair cognitive function. By maintaining optimal hydration, we can support our mental well-being and promote a positive mindset.`],
      image: r5,
    },
  ];

  const benefits = [
    {
      title: `1. Enhanced Physical Performance`,
      description: [`When we drink enough water, our bodies are properly hydrated, allowing us to perform physical activities more efficiently. `,`Whether it's exercising, playing sports, or simply going about our daily tasks, optimal hydration supports our muscles, joints, and overall physical endurance.`],
      image: b1,
    },
    {
      title: `2. Improved Digestion and Nutrient Absorption`,
      description: [`
      Sufficient water intake aids in proper digestion, preventing constipation and promoting regular bowel movements.`,` It also helps our bodies absorb essential nutrients from the food we consume, ensuring that we derive maximum benefits from our diets.`],
      image: b2,
    },
    {
      title: `3. Healthier Skin`,
      description: [`Hydration plays a crucial role in maintaining healthy skin. `,`Ample water consumption helps flush out toxins, improves blood circulation, and promotes the elasticity and suppleness of our skin, contributing to a youthful and radiant appearance.`],
      image: b3,
    },
    {
      title: `4. Enhanced Cognitive Function`,
      description: [`Drinking enough water supports optimal brain function. Proper hydration improves concentration, memory, and overall cognitive performance. `,`By keeping our brains hydrated, we can think more clearly, make better decisions, and stay mentally sharp.`],
      image: b4,
    },
    {
      title: `5. Improved Mood and Mental Well-being`,
      description: [`Hydration has a direct impact on our mental health. Staying hydrated can boost our mood, alleviate anxiety, and enhance our overall sense of well-being. `,`By taking care of our hydration needs, we create a positive foundation for mental and emotional resilience.`],
      image: b5,
    },
  ];

  const imageStyle = {
    aspectRatio: '3/2',
    objectFit: 'cover',
    display: 'flex',
    width: '100%',
  };
  return (
    <div className="article-container">
    <Helmet>
      <title>The Benefits of Hydration - Toronto Tech Gear</title>
      <meta name='description' content='Drinking the right amount of water can improve your physical and mental health, athletic performance, and digestion. Are you drinking enough?'/>
    </Helmet>
      <ExternalHeader nextArticleLink='/exercise-mental-health-article' nextArticleTitle='Exercise for Mental Health' ttglogo={true}/>
      <div className="body-padding">
        <div id="title">
          <h1 className='mb-4 mt-8'>
            The Power of Hydration: Unleashing the Health Benefits of Drinking
            Enough Water
          </h1>
          <p>
            June 29<sup>th</sup>, 2023
          </p>
        </div>
        <Container fluid>
          <Row>
            <Col  className='mt-4' xl={8} md={12} sm={12}>
              <img style={imageStyle} alt="header" src={HeaderImage} />
            </Col>
            <Col className='mt-4'>
              <p  style={{ display: 'flex' }}>
                Water, the elixir of life, is essential for our overall
                well-being. It plays a vital role in various bodily functions,
                and its consumption is crucial for maintaining good health.<br/><br/>
                While the importance of drinking enough water is widely known,
                many individuals still underestimate its impact on our bodies.
                <br/><br/>In this article, we will explore the potential risks associated
                with not drinking enough water on a daily basis, highlighting
                the significant benefits that arise when we prioritize
                hydration.
              </p>
            </Col>
          </Row>
        </Container>
        <h1 className='mt-16'>The Risks</h1>
        {risks.map((r, index) => (
          <div>
            <h2 style={{paddingTop:'3rem'}}>{r.title}</h2>
            <Container >
              <Row>
                <Col xl={8} md={12} sm={12}>
                  <img
                    className='mt-2'
                    style={imageStyle}
                    alt={index.toString() + '-b'}
                    src={r.image}
                  />
                </Col>
                <Col>
                  <div style={{ display: 'flex',flexDirection:'column', paddingTop:'1rem'}}>{
                    r.description.map((item)=>(<p>{item}</p>))
                    }
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ))}
        <h1 style={{paddingTop:'3rem'}}>The Benefits</h1>
        <p style={{fontSize:'large'}}>
          Though these risks might sound daunting, the good news is that they
          can be mitigated by simply incorporating sufficient water intake into
          our daily routines. <br/><br/>By making hydration a priority, we unlock an array
          of health benefits:
        </p>

        {benefits.map((r, index) => (
          <div className='mb-8'>
            <h2>{r.title}</h2>
            <Container fludi>
              <Row>
                <Col  xl={8} md={12} sm={12}>
                  <img
                    className='mt-2'
                    style={imageStyle}
                    alt={index.toString() + '-b'}
                    src={r.image}
                  />
                </Col>
                <Col>
                <div style={{ display: 'flex',flexDirection:'column', paddingTop:'1rem'}}>{
                    r.description.map((item)=>(<p>{item}</p>))
                    }
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ))}
        <p style={{paddingTop:'2rem', fontSize:'large'}}>You might be wondering…</p>

        <h1 className='mb-4'>
          How Much Water Should I Consume to Get All the Benefits and Avoid the
          Risks?
        </h1>
        <img style={imageStyle} alt="header" src={FooterImage} />
        <p style={{paddingTop:'1rem'}}>
          Our bodies require approximately 2 liters of water each day to
          maintain proper hydration levels.
        </p>

        <p>
          This recommended amount serves as a general guideline, as individual
          water needs may vary based on factors such as age, weight, activity
          level, and climate. Water is essential for numerous bodily functions.
          It helps regulate body temperature, lubricates joints, aids in
          digestion, transports nutrients and oxygen to cells, and facilitates
          the removal of waste products through urine and sweat.{' '}
        </p>

        <p>
          By consuming around 2 liters of water daily, we provide our bodies
          with the necessary hydration to support these vital functions and
          maintain overall health and well-being.{' '}
        </p>
        <h1>We Are Here to Help</h1>
        <p>
          Toronto Tech is on a mission to improve people’s wellbeing through
          nutrition, hydration, and exercise. That is why we have created the
          perfect gear to help you meet your daily hydration needs.
        </p>
        <div className="mt-16 items-center flex-col justify-center gap-10 md:mt-4 md:flex">
          {/* GRAPHIC */}
          <div className="flex items-center justify-center gap-4 ">
            <ColorButton
              onClick={() => {
                setButtonColor('bg-blueBottle');
                setSBottle(bottleColors.blue);
              }}
              color="bg-blueBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-gray-600');
                setSBottle(bottleColors.black);
              }}
              color="bg-blackBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-tealDark');
                setSBottle(bottleColors.teal);
              }}
              color="bg-tealBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-pinkDark');
                setSBottle(bottleColors.pink);
              }}
              color="bg-pinkBottle"
            />
          </div>
          <img
            style={{ objectFit: 'cover', maxWidth: '100%', maxHeight:'80%' }}
            alt="benefits-page-graphic"
            src={explodedView[sbottle]}
            className='mb-2 md:mb-0'
          />
          {/* DESCRIPTION */}
          <div style={{textDecoration:'none'}} className="w-full  items-center justify-center flex md:mt-0 xs:mt-4 ">
            <ActionButton
              color={buttonColor}
              href={learnMoreLink}
              target="_blank"
            >
              Learn More
            </ActionButton>
          </div>
        </div>
        <div className='mt-10 mb-10'>
        <h1>Follow us</h1>
        <SocialMediaIcons/>
        </div>

        <h2>Sources:</h2>
        <p>
          Centers for Disease Control and Prevention: Benefits of Drinking
          Water. Source:{' '}
          <p className="source">
            https://www.cdc.gov/healthyweight/healthy_eating/water-and-healthier-drinks.html#
          </p>
        </p>
        <p>
          Mayo Clinic: Dehydration - Symptoms and causes: Source:{' '}
          <p className="source">
            https://www.mayoclinic.org/diseases-conditions/dehydration/symptoms-causes/syc-20354086
          </p>
        </p>
        <p>
          Harvard Health Publishing: The importance of staying hydrated: Source:{' '}
          <p className="source">
            https://www.health.harvard.edu/staying-healthy/the-importance-of-staying-hydrated
          </p>
        </p>
        <p>
          National Kidney Foundation: Kidney Stones: Source:{' '}
          <p className="source">
            https://www.kidney.org/atoz/content/kidneystones
          </p>
        </p>
        <p>
          American Migraine Foundation: Dehydration and Headaches: Source:{' '}
          <p className="source">
            https://americanmigrainefoundation.org/resource-library/dehydration-and-headaches/
          </p>
        </p>
        <p>
          Frontiers in Human Neuroscience: Mild Dehydration Modulates Mood in
          Healthy Young Women:{' '}
          <p className="source">
            Source:
            https://www.frontiersin.org/articles/10.3389/fnhum.2018.00188/full
          </p>
        </p>
        <p>
          Nutrition and Healthy Aging: Water, Hydration, and Health: Source:{' '}
          <p className="source">
            https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2908954/
          </p>
        </p>
        <Footer/>
      </div>
    </div>
  );
};

export default WaterArticle;
