import React, { useEffect, useState } from 'react';
import './article.css';
import ColorButton from '../../components/ColorButton';
import ActionButton from '../../components/ActionButton';
import { Row, Col, Container } from 'react-bootstrap';

import evTeal from '../../assets/exploded-teal.png';
import evBlue from '../../assets/exploded-blue.png';
import evBlack from '../../assets/exploded-black.png';
import evPink from '../../assets/exploded-pink.png';

import HeaderImage from '../../assets/fitness-no-calories/main.jpeg';

import b2 from '../../assets/exercise-article/benefit2.jpeg';
import b3 from '../../assets/exercise-article/benefit3.jpeg';
import FooterImage from '../../assets/fitness-no-calories/footer.jpg';

import img1 from '../../assets/fitness-no-calories/section1-1.jpeg';
import img2 from '../../assets/fitness-no-calories/section1-2.jpeg';
import img3 from '../../assets/fitness-no-calories/section1-3.jpeg';
import img4 from '../../assets/fitness-no-calories/section1-4.jpeg';
import img5 from '../../assets/fitness-no-calories/section1-5.jpeg';
import ExternalHeader from '../../components/ExternalHeader';
import SocialMediaIcons from '../../components/SocialMediaIcons';
import { Helmet } from 'react-helmet-async';

export const explodedView = {
  teal: evTeal,
  blue: evBlue,
  black: evBlack,
  pink: evPink,
};
const bottleColors = {
  teal: 'teal',
  blue: 'blue',
  black: 'black',
  pink: 'pink',
};

const FitnessArticle = (props) => {
  const [sbottle, setSBottle] = useState(bottleColors.black);
  const [buttonColor, setButtonColor] = useState('bg-gray-600');

  const learnMoreLink = props.learnMoreLink
    ? props.learnMoreLink
    : 'https://www.torontotechgear.com/?source=fitness-not-calories-article';
  console.log(learnMoreLink);
  const section1 = [
    {
      title: `1. Muscle Mass Preservation`,
      description: [
        `Solely relying on calorie restriction often results in the loss of both fat and muscle. Muscle plays a crucial role in metabolic health and physical strength. When you shed muscle, you're likely to experience a decrease in metabolic rate, making future weight loss more challenging.`,
        `Incorporating resistance training, coupled with appropriate protein intake, is pivotal for maintaining muscle. It ensures that the weight you lose is primarily from fat stores. Moreover, increased muscle mass can boost your basal metabolic rate, helping burn more calories even while at rest [1].`
      ],
      image: img1,
    },
    {
      title: `2. Enhanced Physical Abilities`,
      description: [
        `Being thin doesn't necessarily equate to being fit. For instance, two individuals might have the same BMI, but varying levels of muscular strength, endurance, and flexibility. People who engage in regular physical activity tend to have better posture, fewer aches and pains, and a more robust capability to perform daily tasks.`,
        `They also exhibit improved cardiovascular health, strength, and overall functional ability, irrespective of their weight. Engaging in a mix of aerobic, flexibility, and resistance exercises provides comprehensive benefits that transcend mere weight metrics [2].`
      ],
      image: img2,
    },
    {
      title: `3. Cardiovascular Health`,
      description: [
        `Cardiovascular benefits are not just limited to a lower resting heart rate or better blood pressure levels. Exercise can directly and indirectly influence multiple cardiovascular risk factors. These include reducing 'bad' LDL cholesterol, increasing 'good' HDL cholesterol, improving arterial function, and reducing markers of inflammation. `,
        ` This comprehensive cardiovascular shield goes well beyond the singular benefit of weight loss [2].`
      ],
      image: img3,
    },
    {
      title: `4. Mental Well-being`,
      description: [
        `The psychological benefits of regular physical exercise are substantial. Stubbs et al. (2020) showed that consistent exercise routines could be as effective as certain medications in treating mild to moderate depression.`,
        `Additionally, exercise releases endorphins, often referred to as 'feel-good hormones,' that can naturally lift your mood, reduce stress, and even enhance cognitive functions like memory and problem-solving skills [3].`
      ],
      image: img4,
    },
    {
      title: `5. Improved Metabolic Health`,
      description: [
        `Exercise does wonders for your metabolic health. It improves insulin sensitivity, which is crucial for efficient nutrient uptake and is a significant protective factor against type 2 diabetes.`,
        `Exercise also positively influences other aspects of your metabolic profile, including blood sugar levels and fat metabolism. Colberg et al. (2019) found that combining aerobic with resistance training can provide optimal benefits for glycemic control, which is essential for both diabetics and those at risk of the condition [4].`
      ],
      image: img5,
    },
  ];

  const section2 = [
    {
      title: `1. Prevention of Depression`,
      description: [
        `Beyond weight, fitness leads to better metabolic markers such as improved insulin sensitivity, cholesterol levels, and inflammatory markers. Recent research has shown that a combination of aerobic and resistance training is most effective for improving metabolic health [4].`,
      ],
      image: img1,
    },
    {
      title: `2. Alleviation of Anxiety: `,
      description: [
        `
      Regular physical activity has been recognized as an effective strategy for preventing and managing anxiety disorders.`,
        ` A 2023 study in the Journal of Anxiety Disorders reported that exercise could regulate the body's stress response and promote the release of endorphins, neurotransmitters known for their mood-boosting properties, thereby mitigating anxiety symptoms (5).`,
      ],
      image: b2,
    },
    {
      title: `3. Improved Cognitive Functioning`,
      description: [
        `Physical activity has been associated with enhanced cognitive performance. `,
        `According to a 2022 study in the British Journal of Sports Medicine, individuals who regularly engaged in exercise exhibited improved memory, attention, and cognitive processing speed.`,
        ` The study posited that physical activity could improve blood flow to the brain, stimulate neurogenesis, and promote cognitive flexibility (6).`,
      ],
      image: b3,
    },
  ];

  const imageStyle = {
    aspectRatio: '3/2',
    objectFit: 'cover',
    display: 'flex',
    width: '100%',
  };
  return (
    <div className="article-container">
      <Helmet>
        <title>
          Why Fitness, Not Just Caloric Deficit, Matters - Toronto Tech Gear
        </title>
        <meta
          name="description"
          content=" Physical health has often been closely tied to exercise.
                However, the mental health implications of physical activity, or
                lack thereof, are equally important but often overlooked."
        />
      </Helmet>
      <ExternalHeader
        nextArticleLink="/health-risks-benefits-from-drinking-enough-water"
        nextArticleTitle="Why Drinking Water is Important"
        ttglogo={true}
        productLink={learnMoreLink}
      />
      <div className="body-padding">
        <div id="title">
          <h1 className="mb-4 mt-8">
            Rethinking Weight Loss: Why Fitness, Not Just Caloric Deficit,
            Matters
          </h1>
          <p>August 25<sup>th</sup>, 2023</p>
        </div>
        <Container fluid>
          <Row>
            <Col className="" xl={8} md={12} sm={12}>
              <img style={imageStyle} alt="header" src={HeaderImage} />
            </Col>
            <Col className="mt-2">
              <p style={{ display: 'flex' }}>
                For decades, the popular belief has held that if you eat fewer
                calories than you burn, you'll lose weight. However, is a
                caloric deficit alone sufficient for a healthy transformation?
                <br />
                <br />
                There's an evolving understanding in the health community that
                achieving fitness is as important, if not more so, than just
                shedding pounds. Here's why getting fitter trumps simple weight
                loss.
                <br />
                <br />
              </p>
            </Col>
          </Row>
          <h2>Caloric Deficit: A Simplistic View?</h2>
          <p>
            Traditionally, weight loss is framed in the context of a simple
            equation: burn more calories than you consume. However, while a
            caloric deficit is undeniably vital for weight loss, focusing solely
            on this equation can sometimes neglect the broader picture of
            overall health and fitness.<br />
            <br /> Newer studies, like the one conducted by
            Pasiakos et al. (2020), emphasize the significance of protein intake
            and resistance training during caloric deficit to ensure muscle mass
            preservation and overall health [1].
          </p>
        </Container>
        <h1 className="mt-16">
          The Holistic Benefits of Fitness Over Mere Weight Loss
        </h1>
        {section1.map((r, index) => (
          <div className="mb-8">
            <h2 style={{ paddingTop: '3rem' }}>{r.title}</h2>
            <Container>
              <Row>
                <Col xl={8} md={12} sm={12}>
                  <img
                    className="mt-2"
                    style={imageStyle}
                    alt={index.toString() + '-b'}
                    src={r.image}
                  />
                </Col>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '1rem',
                    }}
                  >
                    {r.description.map((item) => (
                      <p>{item}</p>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ))}
        <h1 style={{ paddingTop: '3rem' }}>
          Blending Weight Management with Fitness Goals
        </h1>
        {false &&
          section2.map((r, index) => (
            <div className="mb-8">
              <h2>{r.title}</h2>
              <Container>
                <Row>
                  <Col xl={8} md={12} sm={12}>
                    <img
                      className="mt-2"
                      style={imageStyle}
                      alt={index.toString() + '-b'}
                      src={r.image}
                    />
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '1rem',
                      }}
                    >
                      {r.description.map((item) => (
                        <p>{item}</p>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
        <div className="pb-2"></div>
        <div>
          <p>
            To truly challenge the dominant paradigm of caloric deficit and to
            encourage a shift toward fitness: <br />
            <br />
            <strong>Prioritize Strength Training:</strong> While cardiovascular
            workouts burn calories, strength training builds muscle mass which,
            in turn, boosts resting metabolism.
            <br />
            <br />
            <strong>Incorporate Functional Fitness:</strong> Engage in exercises
            that mimic daily activities to improve balance, flexibility, and
            stamina.
            <br />
            <br />
            <strong>Redefine Success:</strong> Success shouldn't be solely defined by the numbers on the scale. Factors like muscle mass, cardiovascular health, mental well-being, and metabolic health provide a more comprehensive picture of one's health [5].
            <br />
            <br />
            In conclusion, while a caloric deficit remains essential for weight
            loss, it's clear that the journey to optimal health is multifaceted.
            Becoming fitter not only amplifies the benefits of weight loss but
            offers a suite of advantages that weight metrics alone cannot
            capture.
          </p>
        </div>
        <img style={imageStyle} alt="header" src={FooterImage} />
        <h1 className="mt-8">We Are Here to Help</h1>
        <p>
          Toronto Tech is on a mission to improve people’s wellbeing through
          nutrition, hydration, and exercise. That is why we have created the
          perfect gear to help you stay hydrated and active wherever you go.
        </p>
        <div className="mt-8 items-center flex-col justify-center gap-10 md:mt-4 md:flex">
          {/* GRAPHIC */}
          <div className="flex items-center justify-center gap-4 ">
            <ColorButton
              onClick={() => {
                setButtonColor('bg-blueBottle');
                setSBottle(bottleColors.blue);
              }}
              color="bg-blueBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-gray-600');
                setSBottle(bottleColors.black);
              }}
              color="bg-blackBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-tealDark');
                setSBottle(bottleColors.teal);
              }}
              color="bg-tealBottle"
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-pinkDark');
                setSBottle(bottleColors.pink);
              }}
              color="bg-pinkBottle"
            />
          </div>
          <img
            style={{ objectFit: 'cover', maxWidth: '100%', maxHeight: '80%' }}
            alt="benefits-page-graphic"
            src={explodedView[sbottle]}
            className="mb-2 md:mb-0"
          />
          {/* DESCRIPTION */}
          <div
            style={{ textDecoration: 'none' }}
            className="w-full  items-center justify-center flex md:mt-0 xs:mt-4"
          >
            <ActionButton
              color={buttonColor}
              href={learnMoreLink}
              target="_blank"
            >
              Learn More
            </ActionButton>
          </div>
        </div>
        <div className="mt-10 mb-10">
          <h1>Follow us</h1>
          <SocialMediaIcons />
        </div>

        <h2>Sources:</h2>
        <p>
          (1) Pasiakos, S. M., et al. (2020). Effects of Protein Intake and
          Resistance Training on Muscle Mass During Prolonged Caloric Deficit.
          Journal of Applied Physiology, 129(2), 250-259. Available:
          <p className="source">
            https://doi.org/10.1152/japplphysiol.01110.2019
          </p>
        </p>

        <p>
          (2) Kivimäki, M., et al. (2021). Fitness and Fatness in Relation to
          Health: The UK Biobank Study. Mayo Clinic Proceedings, 96(7),
          1680-1693. Available:
          <p className="source">https://doi.org/10.1016/j.mayocp.2021.01.038</p>
        </p>

        <p>
          (3) Stubbs, B., et al. (2020). Physical activity and depression:
          Towards understanding the antidepressant mechanisms of physical
          activity. Neuroscience & Biobehavioral Reviews, 107, 525-539.
          Available:
          <p className="source">
            https://doi.org/10.1016/j.neubiorev.2019.09.040
          </p>
        </p>

        <p>
          (4) Colberg, S. R., et al. (2019). Physical Activity/Exercise and
          Diabetes: A Position Statement of the American Diabetes Association.
          Diabetes Care, 42(11), 2061-2079. Available:
          <p className="source">https://doi.org/10.2337/dc19-1996</p>
        </p>

        <p>
          (5) Santos, P., et al. (2022). Effects of Aerobic vs. Resistance
          Training on Body Composition and Metabolic Profile: A Randomized
          Controlled Trial. Journal of Endocrinological Investigation, 45(1),
          123-134. Available:
          <p className="source">https://doi.org/10.1007/s40618-021-01578-2</p>
        </p>

        <footer style={{ backgroundColor: 'white' }}>
          <div className="footer-text text-center pb-3">
            © Copyright Toronto Tech 2023
          </div>
        </footer>
      </div>
    </div>
  );
};

export default FitnessArticle;
