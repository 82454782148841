/* This file contains the structure of the app. It creates the navigation bar and indicates the routes pointing to
the screens */
import './App.css';
import './index.css';
import {Routes, Route, HashRouter } from 'react-router-dom';
import WaterArticle from './screens/Articles/WaterArticle';
import ExerciseArticle from './screens/Articles/ExerciseArticle'
import React from 'react';
import {HelmetProvider} from 'react-helmet-async'
import FitnessArticle from './screens/Articles/FitnessNotCalories';
import Articles from './screens/Articles/Articles';
import HydrationBoostsNutritionArticle from './screens/Articles/HydrationBoostsNutritionArticle';
import StopFeelingTired from './screens/Articles/StopFeelingTired';
import NewYearGoals from './screens/Articles/NewYearGoalSetting';
import MuscleSoreness from './screens/Articles/MuscleSoreness';

function App(props) {
  return (
    <HelmetProvider>
    <HashRouter>
            <Routes>
              <Route path="/" element={<Articles />}/>
              <Route path="/health-risks-benefits-from-drinking-enough-water" element={<WaterArticle />}/>
              <Route path="/exercise-mental-health-article" element={<ExerciseArticle />}/>
              <Route path="/fitness-not-calories" element={<FitnessArticle />}/>
              <Route path='/hydration-boosts-nutrition' element={<HydrationBoostsNutritionArticle/>}/>
              <Route path="/articles" element={<Articles />}/>
              <Route path="/why-tired-increase-energy" element={<StopFeelingTired/>}/>
              <Route path='/achieve-new-year-goals' element={<NewYearGoals/>}/>
              <Route path ='/no-pain-no-gain-lies' element={<MuscleSoreness/>}/>
            </Routes>
    </HashRouter>
    </HelmetProvider>
  );
}

export default App;
