
import React, { ReactComponentElement } from "react"


const ActionButton = ({children, icon,onClick,color,href, target}) => {
  return (
    <a className={`w-full flex-1 items-center justify-center rounded-md ${color? color:'bg-secondary-500'} min-w-[180px] max-w-[250px] text-center px-6 py-3 hover:bg-black ${color?'text-white':'hover:text-white'} hover:cursor-pointer z-10`}
    onClick={onClick}
    href={href}
    target={target}
    style={{textDecoration:'none', fontWeight:'bold'}}>
        {children}
        {icon}
    </a>
  )
}

export default ActionButton