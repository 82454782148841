import { HashLink } from 'react-router-hash-link';
import ActionButton from './ActionButton';

const ArticleCard = (props) => {
  const { image, title, route,date } = props;
  return (
    <div className="flex flex-col max-w-md rounded-lg border pb-4 bg-white drop-shadow-lg items-center px-0 ">
      <div className="flex flex-col">
        <img alt={image + 'img'} className="w-full rounded-t-lg max-h-[300px] object-cover" src={image} />
        <div className='pt-4 px-4'>
        <h2 className="text-2xl mb-0 pb-2 tex-black font-bold font-monserrat">
          {title}
        </h2>
        <p className='mt-0'>{date}</p>
      </div>
      </div>
      {route && <HashLink to={route} className="nav-link flex w-auto"><ActionButton color={'bg-ad-gray4'}>Read Article</ActionButton></HashLink>}
    </div>
  );
};

export default ArticleCard;
