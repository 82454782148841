import HeaderImage1 from './assets/exercise-article/header.jpeg'
import HeaderImage2 from './assets/water-article/header.png'
import HeaderImage3 from './assets/fitness-no-calories/main.jpeg'
import HeaderImage4 from './assets/hydration-boosts-nutrition/header.jpeg'

const HeaderImage5 =
  'https://res.cloudinary.com/dgf1xlybh/image/upload/v1702218839/toronto-tech-gear/articles/otl38hh4rau19b4ajns5.png'

const HeaderImage6 = 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1704633525/toronto-tech-gear/articles/lbbcgppugqgr9q3gxaeo.png'
const HeaderImage7 = 'https://res.cloudinary.com/dgf1xlybh/image/upload/v1709993833/toronto-tech-gear/articles/7_pqssmp.png'

export const ArticlesList = [
  {
    title: 'The Lies Behind No Pain, No Gain: 3 Takeaways to Optimize Your Workouts',
    image: HeaderImage7,
    route: '/no-pain-no-gain-lies',
    number: '7',
    date: 'March 9, 2024',
  },
  {
    title: 'How the Top 8% Achieve Their New Year Goals: 5 Strategies',
    image: HeaderImage6,
    route: '/achieve-new-year-goals',
    number: '6',
    date: 'January 7, 2024',
  },
  {
    title: 'Why do I Feel Tired? How to Increase Your Energy Levels Naturally',
    image: HeaderImage5,
    route: '/why-tired-increase-energy',
    number: '5',
    date: 'December 10, 2023',
  },
  {
    title: 'The Science of Hydration: A Key to Optimal Nutrient Absorption',
    image: HeaderImage4,
    route: '/hydration-boosts-nutrition',
    number: '4',
    date: 'September 10, 2023',
  },
  {
    title: 'The Importance of Exercise for Mental Health',
    image: HeaderImage1,
    route: '/exercise-mental-health-article',
    number: '1',
    date: 'August 1, 2023',
  },
  {
    title: 'The Health Benefits of Drinking Enough Water',
    image: HeaderImage2,
    route: '/health-risks-benefits-from-drinking-enough-water',
    number: '2',
    date: 'June 29, 2023',
  },
  {
    title: 'Why Fitness, Not Just Caloric Deficit, Matters',
    image: HeaderImage3,
    route: '/fitness-not-calories',
    number: '3',
    date: 'August 25, 2023',
  },
]
