import React from 'react'

const Footer = () => {
  return (
    <footer style={{backgroundColor:'white', paddingTop:'1rem'}}>
          <div className="footer-text text-center pb-3">© Copyright Toronto Tech Gear 2024</div>
    </footer>
  )
}

export default Footer