import React, { useState } from 'react'
import './article.css'
import ColorButton from '../../components/ColorButton'
import ActionButton from '../../components/ActionButton'
import { Row, Col, Container } from 'react-bootstrap'
import Footer from '../../components/Footer'

import evTeal from '../../assets/exploded-teal.png'
import evBlue from '../../assets/exploded-blue.png'
import evBlack from '../../assets/exploded-black.png'
import evPink from '../../assets/exploded-pink.png'

import ExternalHeader from '../../components/ExternalHeader'
import SocialMediaIcons from '../../components/SocialMediaIcons'
import { Helmet } from 'react-helmet-async'

const HeaderImage =
  'https://res.cloudinary.com/dgf1xlybh/image/upload/v1704633009/toronto-tech-gear/articles/ew6x25h1ni1hrla7r5mg.png'

export const explodedView = {
  teal: evTeal,
  blue: evBlue,
  black: evBlack,
  pink: evPink,
}
const bottleColors = {
  teal: 'teal',
  blue: 'blue',
  black: 'black',
  pink: 'pink',
}

type SectionType = {
  title: string
  subsections?: SubsectionType[]
  content?: JSX.Element[]
  image?: string
}

type SubsectionType = {
  title: string
  image: string
  content: JSX.Element[]
}

type ArticleContentType = {
  title: string
  subtitle?: string
  date: JSX.Element
  introduction: JSX.Element
  sections: SectionType[]
  sources: JSX.Element[]
}

const Article = (props) => {
  const articleContent: ArticleContentType = {
    title: 'How the Top 8% Achieve Their New Year Goals: 5 Strategies',
    subtitle: 'Most people quit by January 12th',
    date: (
      <p>
        January 7<sup>th</sup>, 2023
      </p>
    ),
    introduction: (
      <div>
        <p id='dc5f017c-15ce-4c3b-8808-24c1e53790ea'>
          The tradition of setting New Year&#x27;s resolutions is as enduring as it is challenging.
          While the practice is steeped in optimism, the <strong>reality often falls short.</strong>
        </p>
        <p id='8148f4dd-eee8-473a-8729-3b2852d21412'>
          A comprehensive study by the University of Scranton found that{' '}
          <strong>only 8% of people achieve their New Year&#x27;s goals.</strong> Further, a survey
          by Strava, a social network for athletes, identified the second Friday in January{' '}
          <strong>(January 12th this year)</strong> as the
          <strong> &quot;Quitters&#x27; Day,&quot;</strong> the day when most people are likely to
          give up on their resolutions.
        </p>
        <p id='39b8b32c-4432-4ab6-9c49-fe53606f76c0'>
          This stark contrast between aspiration and achievement highlights a need for effective
          strategies to bridge this gap.
        </p>
      </div>
    ),
    sections: [
      {
        title: 'How to Join the Top 8% of High Achievers',
        subsections: [
          {
            title: '1. Be (Extremely) Specific',
            image:
              'https://res.cloudinary.com/dgf1xlybh/image/upload/v1704632973/toronto-tech-gear/articles/v6gt72yobcgyy0ioslo5.webp',
            content: [
              <p id='77583438-bc80-42bd-b375-a6c8ad2cfb5a'>
                Define goals with precision and clarity. SMART goals are goals that are specific,
                measurable, achievable, relevant and time-bound.
              </p>,
              <p id='2f688c25-a9ac-4602-a6e4-1c9a9ea253b7'>
                For instance, instead of saying &quot;lose weight,&quot; a{' '}
                <strong>
                  SMART goal would be &quot;lose 10 pounds in 3 months by exercising thrice a week
                  and adopting a healthy diet.&quot;
                </strong>{' '}
                This transforms an abstract aspiration into a concrete, actionable plan.
              </p>,
              <p id='2a4481b0-cf57-4b29-9e2c-d1f990a1f37b'></p>,
            ],
          },
          {
            title: '2. Break Down Goals into Smaller Ones and Daily Habits',
            content: [
              <p id='c52151cd-275c-4994-915c-540e0b68210f'>
                For a goal like &quot;learn a new language,&quot; break it down into daily or weekly
                tasks such as &quot;practice 20 minutes daily with a language learning app&quot; or
                &quot;complete one language lesson every week.&quot;
              </p>,
              <p id='898c2f2f-d64f-4689-8bdd-a9560648be90'>
                Breaking goals into smaller steps significantly enhances the likelihood of success.
              </p>,
            ],
            image:
              'https://res.cloudinary.com/dgf1xlybh/image/upload/v1704633009/toronto-tech-gear/articles/cjbctpojkxrud7rsjitf.png',
          },
          {
            title: '3. Celebrate Your Wins',
            content: [
              <p id='477325e0-58aa-437e-88aa-e4c8d3e350a0'>
                Set mini-milestones within your larger goal and celebrate when you reach them.{' '}
                <strong>
                  If your goal is to save a certain amount of money, treat yourself to a small
                  reward each time you save a quarter of your target amount.
                </strong>
              </p>,
              <p id='04443fb9-99a3-409b-a53e-8e958792f89e'>
                The part of our brains which control our emotions has no knowledge of the future; to
                get it to cooperate and not get on your way, you must use positive reinforcement.
              </p>,
            ],
            image:
              'https://res.cloudinary.com/dgf1xlybh/image/upload/v1704632973/toronto-tech-gear/articles/yx5sockx35qxpna117ja.png',
          },
          {
            title: '4. Get an Accountability Partner',
            content: [
              <p id='3cba4af4-cec7-44ed-ad39-4c01c36c2ffa'>
                Choose an accountability partner who shares a similar goal or has a genuine interest
                in your success. Schedule regular weekly check-ins to discuss progress, challenges,
                and next steps. This regular interaction encourages consistency and responsibility.
              </p>,
              <p id='3f6714bd-0f71-4f5e-957e-4fc5d8e79643'>
                If you don’t have anyone, you can also put up a scoreboard on your room where you
                can see it every day.
              </p>,
            ],
            image:
              'https://res.cloudinary.com/dgf1xlybh/image/upload/v1704632973/toronto-tech-gear/articles/see0t9qkl8a9lu6s5q1c.png',
          },
          {
            title: '5. Regular Self-Reflection',
            content: [
              <p id='97cc2731-7547-48f0-aeeb-d2e8d896a57d'>
                Dedicate time each week to reflect on your progress.{' '}
                <strong>Ask yourself what worked well, what didn’t</strong>, and how you can
                improve. Use this time to <strong>adjust</strong> your strategies or{' '}
                <strong>set new mini-goals</strong> to maintain momentum.
              </p>,
              <p id='e371cf6f-b658-4595-9763-ebec9f3fc75b'>
                You can do this daily or weekly. You can do it at night or before you start your
                day, the key is to stay consistent.
              </p>,
            ],
            image:
              'https://res.cloudinary.com/dgf1xlybh/image/upload/v1704633384/toronto-tech-gear/articles/sdrvsbuj752yuuz9wayd.jpg',
          },
        ],
      },
      {
        title: 'Final Words',
        subsections: [
          {
            title: '',
            content: [
              <p id='8403a450-2b87-41c7-83c8-b4dcd1517b7a'>
                As we navigate the journey from aspiration to achievement, it&#x27;s essential to
                remember that success in New Year&#x27;s resolutions is more than just a fleeting
                wish – <strong>it&#x27;s a process</strong> of transformation.
              </p>,
              <p id='d9301efe-5fdf-4e6b-a1a6-109a3db34702'>
                The strategies outlined in this article are not just steps but powerful tools to
                guide you into the exclusive circle of high achievers. Everyone <strong>can</strong>
                do it. However, it is recommended to start by taking small steps and <strong>sticking to the
                new habits.</strong> Then adjust based on your experience and specific needs.
              </p>,
            ],
            image:
              'https://res.cloudinary.com/dgf1xlybh/image/upload/v1704633525/toronto-tech-gear/articles/lbbcgppugqgr9q3gxaeo.png',
          },
        ],
        image: '',
      },
    ],
    sources: [
      <p>
        (1) Doran, G. T. (1981). "There’s a S.M.A.R.T. way to write management’s goals and
        objectives." Management Review.
      </p>,
      <p>(4) Matthews, Gail. "Accountability and Written Goals." Dominican University.</p>,
      <p>
        (2) "The Benefits of Task Decomposition in Goal Achievement." Journal of Experimental
        Psychology, 2011.
      </p>,
      <p>
        (3) Woolley, K., and Fishbach, A. (2017). "The Small Area Hypothesis: Small Wins and Feeling
        Close." Journal of Personality and Social Psychology.
      </p>,
      <p>
        (5) Zimmerman, B. J. (2002). "Becoming a Self-Regulated Learner: An Overview." American
        Educational Research Journal.
      </p>,
    ],
  }

  const [sbottle, setSBottle] = useState(bottleColors.black)
  const [buttonColor, setButtonColor] = useState('bg-gray-600')

  const learnMoreLink = props.learnMoreLink
    ? props.learnMoreLink
    : 'https://www.torontotechgear.com/?source=hydration-boost-nutrition-article'
  console.log(learnMoreLink)

  const imageStyle: any = {
    aspectRatio: '3/2',
    objectFit: 'cover',
    display: 'flex',
    width: '100%',
  }
  return (
    <div className='article-container'>
      <Helmet>
        <title>
          How to Achieve 2024 New Year Goals | Most People Quit by Jan 12  - Toronto Tech Gear
        </title>
        <meta
          name='description'
          content='Most people quit on their New Year resolutions by Jan 12th. Only the top 8% achieve their goals. How do they do it? We found 5 strategies.'
        />
      </Helmet>
      <ExternalHeader
        nextArticleLink='/exercise-mental-health-article'
        nextArticleTitle='Exercise for Mental Health'
        ttglogo={true}
        productLink={learnMoreLink}
      />
      <div className='body-padding'>
        <div id='title'>
          <h1 className='mb-0 mt-8 leading-10'>
            {articleContent.title}
          </h1>
          <p className='mb-2 text-gray-600 text-xl mt-1'>
          {articleContent.subtitle}
          </p>
          {articleContent.date}
        </div>
        <Container fluid>
          <Row>
            <Col className='mt-4' xl={8} md={8} sm={12}>
              <img style={imageStyle} alt='header' src={HeaderImage} />
            </Col>
            <Col className='mt-4'>{articleContent.introduction}</Col>
          </Row>
        </Container>
        {articleContent.sections.map((sec, index) => (
          <div key={sec.title} className='mb-8'>
            <h2 className='xs:pt-0 sm:pt-12 w-full text-left'>{sec.title}</h2>
            {sec.subsections?.map((sub, index) => (
              <div>
                <h3 className='xs:pt-0 sm:pt-10'>{sub.title}</h3>
                <Container key={sub.title}>
                  <Row>
                    <Col xl={8} md={12} sm={12}>
                      <img
                        className='mt-2'
                        style={imageStyle as any}
                        alt={sub.title}
                        src={sub.image}
                      />
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingTop: '1rem',
                        }}>
                        {sub.content}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            ))}
          </div>
        ))}
        <div className='pb-0'></div>
        <h1 className='mt-8'>We Are Here to Help</h1>
        <p>
          Toronto Tech is on a mission to improve people’s wellbeing through nutrition, hydration,
          and exercise. That is why we have created the perfect gear to help you stay hydrated and
          active wherever you go.
        </p>
        <div className='mt-8 items-center flex-col justify-center gap-10 md:mt-4 md:flex'>
          {/* GRAPHIC */}
          <div className='flex items-center justify-center gap-2 '>
            <ColorButton
              onClick={() => {
                setButtonColor('bg-blueBottle')
                setSBottle(bottleColors.blue)
              }}
              color='bg-blueBottle'
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-gray-600')
                setSBottle(bottleColors.black)
              }}
              color='bg-blackBottle'
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-tealDark')
                setSBottle(bottleColors.teal)
              }}
              color='bg-tealBottle'
            />
            <ColorButton
              onClick={() => {
                setButtonColor('bg-pinkDark')
                setSBottle(bottleColors.pink)
              }}
              color='bg-pinkBottle'
            />
          </div>
          <img
            style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '80%' }}
            alt='benefits-page-graphic'
            src={explodedView[sbottle]}
            className='mb-2 md:mb-0'
          />
          {/* DESCRIPTION */}
          <div
            style={{ textDecoration: 'none' }}
            className='w-full  items-center justify-center flex md:mt-0 xs:mt-4'>
            <ActionButton color={buttonColor} href={learnMoreLink} target='_blank'>
              Learn More
            </ActionButton>
          </div>
        </div>
        <div className='mt-10 mb-10'>
          <h1>Follow us</h1>
          <SocialMediaIcons />
        </div>

        <h2>Sources:</h2>
        <div className='break-words w-full'>
          {articleContent.sources?.map((source, index) => (
            <p className='break-words' key={index}>
              {source}
            </p>
          ))}
        </div>
        <Footer/>
      </div>
    </div>
  )
}

export default Article
