import React from 'react';
import { FaTiktok, FaInstagram } from 'react-icons/fa';

const SocialMediaIcons = () => {
  const socialMediaLinks = [
    { name: 'TikTok', url: 'https://www.tiktok.com/@torontotech', icon: FaTiktok },
    { name: 'Instagram', url: 'https://www.instagram.com/torontotechgear', icon: FaInstagram },
  ];

  return (
    <div className="flex justify-center space-x-4">
      {socialMediaLinks.map((link) => (
        <a
          key={link.name}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
        >
          {React.createElement(link.icon, { className: 'h-10 w-10 mx-2' })}
        </a>
      ))}
    </div>
  );
};

export default SocialMediaIcons;
